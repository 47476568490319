import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import { useUser } from "@clerk/clerk-react";
import { AssetsService, IncomeService, ExpenseService, LiabilitiesService } from "../../services";
import { useLocation } from 'react-router-dom';
import SettlementCalculation from './settlementCalculation';

const Settlement = () => {
  const location = useLocation();
  const { user } = useUser();
  const assetsService = new AssetsService();
  const liabilitiesService = new LiabilitiesService();
  const incomeService = new IncomeService();
  const expenseService = new ExpenseService();

  const renderPieChart = async () => {
    const xValues = ["Assets", "Liabilities"];
    const yValues = [0, 0];
    const barColors = ["#014921", "#A8151D"];

    const { data: assetsData } = await assetsService.getData({ "userId": user.id });
    if (assetsData?.length) {
      const assetData = assetsData[0];
      const accountAssetsTotal = assetData.account.reduce((acc, curr) => acc + (Number(curr.currentBalance) || 0), 0);
      const investmentAssetsTotal = assetData.investment.reduce((acc, curr) => acc + (Number(curr.currentBalance) || 0), 0);
      const retirementAssetsTotal = assetData.retirement.reduce((acc, curr) => acc + (Number(curr.currentBalance) || 0), 0);
      yValues[0] = accountAssetsTotal + investmentAssetsTotal + retirementAssetsTotal;
    }

    const { data: liabilitiesData } = await liabilitiesService.getData({ "userId": user.id });
    if (liabilitiesData) {
      const liabilityData = liabilitiesData[0];
      const creditCardLiabilitiesTotal = liabilityData.creditCard.reduce((acc, curr) => acc + (Number(curr.currentBalance) || 0), 0);
      const personalLoanLiabilitiesTotal = liabilityData.personalLoan.reduce((acc, curr) => acc + (Number(curr.currentBalance) || 0), 0);
      const studentLoanLiabilitiesTotal = liabilityData.studentLoan.reduce((acc, curr) => acc + (Number(curr.currentBalance) || 0), 0);
      yValues[1] = creditCardLiabilitiesTotal + personalLoanLiabilitiesTotal + studentLoanLiabilitiesTotal;
    }

    const ctx = document.getElementById('myChart').getContext('2d');
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: xValues,
        datasets: [{
          backgroundColor: barColors,
          data: yValues
        }]
      },
    });

  }

  const renderBarChart = async () => {
    const xValues = ["Expense", "Income"];
    const yValues = [0, 0];
    const barColors = ["#A8151D", "#014921"];
    const ctx = document.getElementById('horizontalChart').getContext('2d');

    const { data: expenseData } = await expenseService.getData({ "userId": user.id });
    if (expenseData?.length) {
      const expenseDataParsed = expenseData[0];
      yValues[0] = Number(expenseDataParsed.totalCost);
    }

    const { data: incomeData } = await incomeService.getData({ "userId": user.id });
    if (incomeData?.length) {
      const incomeDataParsed = incomeData[0];
      yValues[1] = Number(incomeDataParsed.yourMonthlyIncome) + Number(incomeDataParsed.spousesMonthlyIncome);
    }

    // Check if there's an existing chart instance and destroy it before creating a new one
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [{
          backgroundColor: barColors,
          data: yValues
        }]
      },
      options: {
        indexAxis: 'y', // Specify the horizontal axis
        barThickness: 20,
        plugins: {
          title: {
            display: false,
            text: "Income & Expense"
          },
          legend: {
            display: false
          }
        }
      }
    });

  }

  useEffect(() => {
    renderPieChart();
    renderBarChart();
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <>
      <div id="netWorth" className='container settlement pt-4 pb-4 mb-4'>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="box">
              <h4 className='mt-2 ms-3'>Your Total Net Worth</h4>
              <canvas id="myChart" style={{ width: '100%', maxWidth: '500px' }} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="box">
              <h4 className='mt-2 ms-3'>Disclaimer</h4>
              <div className='p-3'>
                <ol>
                  <li>
                  Anew is a technology platform, not a law firm. The information Anew provides is not legal advice and should not be a substitute for the advice of a licensed professional. 
                  </li>
                  <br />
                  <li>
                  We attempted our best to ensure the accuracy of the information; however, the product may contain inaccuracies, omissions, defects or other errors. We assume no responsibility for such errors and omissions. 
                  </li>
                  <br />
                  <li>
                  Anew makes no guarantees you will attain a particular result by using the Financial Disclosure & Settlement Planning tools. 
                  </li>
                  <br />
                  <li>
                  You release Anew from any and all liabilities, and you waived your rights to file any and all claims against Anew for its beta product.
                  </li>
                </ol>
                <p>
                  The complete User Agreement is available <a href='https://www.loveanew.co/user-agreement' target='_blank'>here.</a> You may report any glitches or make any inquiries by emailing amy@loveanew.co.
                  </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="box mt-5">
              <h4 className='mt-3 ms-3'>Monthly Income & Expenses</h4>
              <canvas id="horizontalChart" style={{ width: '100%', maxHeight: '350px' }} />
            </div>
          </div>
        </div>
      </div>
      <SettlementCalculation />
    </>
  );
};

export default Settlement;
