import { SignOutButton, useUser } from "@clerk/clerk-react";
import React, { useState, useEffect, } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { IncomeService } from '../services/IncomeService';
import { AssetsService } from '../services/asset.service';
import { LiabilitiesService } from '../services/liabilities.service';
import { ExpenseService } from '../services/expenseService';
import './header.css';
import { UserService } from "../services/User.service";
import { isEmpty, size } from "lodash";
import { isPlanExpired, isTrialPlan } from "../utils/date.utils";
import { ROUTES } from "../routes/routes";

function Sidebar() {
  const navigate = useNavigate();
  const { user } = useUser();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const [income, setIncome] = useState(false);
  const [asset, setAsset] = useState(false);
  const [liabilities, setLiabilities] = useState(false);
  const [expense, setExpense] = useState(false);

  const incomeService = new IncomeService();
  const assetService = new AssetsService();
  const liabilitiesService = new LiabilitiesService();
  const expenseService = new ExpenseService();
  const userService = new UserService();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSecondDropdown = () => {
    setSecondDropdownOpen(!secondDropdownOpen);
  };

  const navigateToSettlement = (id) => {
    if (asset && income && liabilities && expense) {
      navigate('/settlement'+id);
      return
    }
    toast.error("Please complete the Financial Disclosure section to view");
  };
  const getIncome = () => {
    incomeService.getData({ "userId": user.id }).then((response) => {
      if (response.data?.length) {
        setIncome(true)
      };
    });
  };
  const getAsset = () => {
    assetService.getData({ "userId": user.id })
      .then((response) => {
        if (response.data?.length) {
          setAsset(true)
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const getLiabilities = () => {
    liabilitiesService.getData({ "userId": user.id })
      .then((response) => {
        if (response.data?.length) {
          setLiabilities(true)
        };
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const getExpense = () => {
    expenseService.getData({ "userId": user.id }).then((response) => {
      if (response.data?.length) {
        setExpense(true)
      }
    })
  }

  useEffect(() => {
    getIncome();
    getAsset();
    getLiabilities();
    getExpense();
  }, []);

  const getUser = async () => {
    try {
      const { data } = await userService.getUser(user.id);
      if (!isEmpty(data)) {
        const { trialPlanAvailed, onPaidPlan, isSubscribe } = data;
  
        if(!isSubscribe) 
          //new users will be redirected to plans page
          {
            navigate(ROUTES.PLANS);
            return;
        }
        //if trialPlanAvailed is false then user is on trial plan
        //we will let user to access the app
        if (!trialPlanAvailed) return;

        //if trialPlanAvailed is true then user has no paidPlan then redirect to trials page
        if (trialPlanAvailed && !onPaidPlan) {
          navigate(ROUTES.TRIAL);
          return;
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    console.log("Fetching user data")
    getUser();
  }, [user]);

  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand-lg navbar-dark bg-green d-lg-none d-xl-none">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="../logo.jpeg" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link active">
                  <i className="fas fa-home"></i> Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-dollar-sign"></i> Financial Disclosure
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link to="/income" className="dropdown-item">
                      Income
                    </Link>
                  </li>
                  <li>
                    <Link to="/monthly-expense" className="dropdown-item">
                      Expense
                    </Link>
                  </li>
                  <li>
                    <Link to="/assets" className="dropdown-item">
                      Assets
                    </Link>
                  </li>
                  <li>
                    <Link to="/liabilities" className="dropdown-item">
                      Liabilities
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-chart-line"></i> Settlement Planning
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link to="/settlement/:id" className="dropdown-item">
                      Net Worth
                    </Link>
                  </li>
                  <li>
                    <button className="dropdown-item">
                      Distribution Planner
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item">Home Equity Split</button>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <SignOutButton>
                  <button className="btn btn-primary button ps-0">
                    <i className="fas fa-power-off me-1"></i> Sign out
                  </button>
                </SignOutButton>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="d-flex flex-column flex-shrink-0 p-3 sidebar d-none d-lg-block">
        <Link
          to="/"
          className="d-flex align-items-center justify-content-center mb-md-0 text-decoration-none"
        >
          <img src="/assets/img/Anew-primary.png" width={92} />
        </Link>
        <Link
          to="/"
          className="d-flex align-items-center justify-content-center mb-3 mb-md-0 text-decoration-none"
        >
          <img
            className="rounded-circle image"
            src={user.imageUrl}
            width={92}
            height={92}
          />
        </Link>
        <Link
          to="/"
          className="d-flex align-items-center justify-content-center text-decoration-none"
        >
          <p className="name">{user.fullName}</p>
        </Link>

        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link active">
              <i className="fas fa-home"></i> Home
            </Link>
          </li>
          <button
            className="dropdown-btn nav-link d-flex align-items-center justify-content-between"
            onClick={toggleDropdown}
          >
            <div>
              <i className="fas fa-dollar-sign"></i> Financial Disclosure
            </div>
            <i className={`fas fa-chevron-${dropdownOpen ? "up" : "down"}`}></i>
          </button>
          <div
            className={`dropdown-container ps-4`}
            style={{ display: dropdownOpen ? "block" : "none" }}
          >
            <Link to="/income" className="nav-link">
              Income
            </Link>
            <Link to="/monthly-expense" className="nav-link">
              Expense
            </Link>
            <Link to="/assets" className="nav-link">
              Assets
            </Link>
            <Link to="/liabilities" className="nav-link">
              Liabilities
            </Link>
          </div>
          <button
            className="dropdown-btn nav-link d-flex align-items-center justify-content-between"
            onClick={toggleSecondDropdown}
          >
            <div>
              <i className="fas fa-chart-line"></i> Settlement Planning
            </div>
            <i
              className={`fas fa-chevron-${secondDropdownOpen ? "up" : "down"}`}
            ></i>
          </button>
          <div
            className={`dropdown-container ps-4`}
            style={{ display: secondDropdownOpen ? "block" : "none" }}
          >
            <button
              className="nav-link"
              onClick={() => navigateToSettlement("#netWorth")}
            >
              Net Worth
            </button>
            <button
              className="nav-link"
              onClick={() => navigateToSettlement("#distributionPlanner")}
            >
              Distribution Planner
            </button>
            <button
              className="nav-link"
              onClick={() => navigateToSettlement("#homeEquitySplit")}
            >
              Home Equity Split
            </button>
            {/* <button className="nav-link" onClick={() => navigate(ROUTES.PLANS)}>Account & Billing</button> */}
          </div>
          <li className="px-3 listcontainer" style={{marginTop:80}}>
            <Link to="/change-profile" className="subnav_item">
              {" "}
              Account{" "}
            </Link>
            <svg
              width="7"
              height="8"
              viewBox="0 0 7 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.00004 1.0615L4.96223 4.00597L1.07563 7.04952"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </li>
          <li className="px-3 listcontainer">
            <Link
              to="https://billing.stripe.com/p/login/aEUaFOaC77YhbU4eUU"
              //open new window
              target="_blank"
              className="subnav_item "
            >
              {" "}
              Billing{" "}
            </Link>
            <svg
              width="7"
              height="8"
              viewBox="0 0 7 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.00004 1.0615L4.96223 4.00597L1.07563 7.04952"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </li>
          <li className="nav-item px-3">
            <SignOutButton>
              <button className="btn btn-primary button">
                <i className="fas fa-power-off me-1"></i> Sign out
              </button>
            </SignOutButton>
          </li>
        </ul>
        <hr />
      </div>
    </>
  );
}

export default Sidebar;
