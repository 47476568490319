import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './header.css';
const headingMapping = {
  '/': 'Welcome',
  '': 'Welcome',
  '/income': 'Income',
  '/monthly-expense': 'Monthly Expense',
  '/assets': 'Assets',
  '/liabilities': 'Liabilities',
  '/settlement': 'Settlement',
  '/analytics': 'Analytics',
};

const HeaderBar = () => {
  const [header, setHeader] = useState('Welcome');
  const location = useLocation();

  useEffect(() => {
    const header = headingMapping[location.pathname] || 'Welcome';
    setHeader(header);
  }, [location]);

  return (
    <nav className="d-none d-lg-block navbar navbar-expand-lg navbar-light" style={{ backgroundColor: "white" }}>
      <div className="container-fluid">
        <a className="navbar-brand" href="#">{header}</a>
        <i className="far fa-bell bellIconColor"></i>
      </div>
    </nav>
  );
};

export default HeaderBar;
