import { SignIn } from "@clerk/clerk-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { isEmpty } from "lodash";

const { LOGIN } = ROUTES;

const SignInPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname == LOGIN && !isEmpty(location.search)) {
      navigate(LOGIN);
    }
  }, [])

  return (
    <SignIn path="/login" routing="path" signUpUrl="/signup" />
  )
};
 
export default SignInPage;