import React from "react";

const PlanDetails = (props) => {
  const { title, description, price, price2, img } = props;
  return (
    <>
    <div className="d-flex mt-3 justify-content-between">
      <img
        style={{ width: "40px", height: "30px", borderRadius: "6px", marginRight:price2?'18px':'10px' }}
        src={img}
      />
      <div>
        <p className="color-anew anew-h mb-0">{title}</p>
        <p className="color-anew anew-p mb-0">{description}</p>
      </div>
        <div style={{width:'35%'}}>
            <p className="color-anew anew-price mb-0">{price}</p>
        </div>

    </div>
    </>

  );
};

export default PlanDetails;
