import React from 'react';

const PlanPrice = (props) => {
    const { title, price } = props;
    return(
        <div className="d-flex justify-content-between">
          <p className='anew-price-title'>{title}</p>
          <p className='anew-h1'>{price}</p>
        </div>
    )
}
export default PlanPrice;