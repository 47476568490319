import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { ExpenseService } from '../services/expenseService';

const validationSchema = Yup.object().shape({
  housingCost: Yup.number().min(0, "Value must be positive."),
  utilitiesCost: Yup.number().min(0, "Value must be positive."),
  foodCost: Yup.number().min(0, "Value must be positive.").required('Required field.'),
  clothingCost: Yup.number().min(0, "Value must be positive."),
  insuranceCost: Yup.number().min(0, "Value must be positive."),
  unReimbursedMedicalCost: Yup.number().min(0, "Value must be positive."),
  maintenanceCost: Yup.number().min(0, "Value must be positive."),
  householdHelpCost: Yup.number().min(0, "Value must be positive."),
  automobileCost: Yup.number().min(0, "Value must be positive."),
  educationCost: Yup.number().min(0, "Value must be positive."),
  recreationalCost: Yup.number().min(0, "Value must be positive."),
  incomeTaxesCost: Yup.number().min(0, "Value must be positive."),
  miscellaneousCost: Yup.number().min(0, "Value must be positive."),
});

const MonthlyExpenses = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const expenseService = new ExpenseService();
  const [recordId, setRecordId] = useState("");
  const formik = useFormik({
    initialValues: {
      housingCost: "",
      utilitiesCost: "",
      foodCost: "",
      clothingCost: "",
      insuranceCost: "",
      unReimbursedMedicalCost: "",
      maintenanceCost: "",
      householdHelpCost: "",
      automobileCost: "",
      educationCost: "",
      recreationalCost: "",
      incomeTaxesCost: "",
      miscellaneousCost: "",
      totalCost: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.userId = user.id;
      if (recordId) {
        expenseService.update(recordId, values)
        return
      }
      expenseService.create(values)
    }
  });

  const handleChange = (e) => {
    formik.handleChange(e);
    calculateTotal();
  };

  const calculateTotal = () => {
    setTimeout(() => {
      console.log(formik.values);
      const total = Object.entries(formik.values)
        .filter(([key, value]) => key !== 'totalCost' && !isNaN(value) && value !== "")
        .reduce((acc, [key, value]) => acc + parseFloat(value), 0);

      formik.setFieldValue('totalCost', total);
    }, 250);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    setTimeout(() => {
      if (Object.keys(formik.errors).length === 0) {
        toast.success("Monthly Expense Saved");
      }
    }, 300)
  };

  const handleContinue = () => {
    formik.handleSubmit();
    setTimeout(() => {
      if (Object.keys(formik.errors).length === 0) {
        navigate('/assets');
      }
    }, 300)

  };

  const getData = () => {
    expenseService.getData({ "userId": user.id }).then((response) => {
      if (response.data?.length) {
        const data = Object.keys(response.data[0]).reduce((acc, key) => {
          if (response.data[0][key] !== null) {
            acc[key] = response.data[0][key];
          }
          return acc;
        }, {});
        formik.setValues(data);
        setRecordId(data._id)
      }
    })
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container border expenses-bg">
        <div className="d-flex justify-content-center">
          <div className="text-center pt-40">
            <i className="fas-bg fas fa-home fa-2x"></i>
            <h4 className="heading-4 mt-13">Monthly Expenses </h4>
            <p className="justify-content-start expenses-Paragraph mt-13">
              This section asked for very detailed monthly household expenses. Look at your bills, bank and credit card statements to come up with accurate averages. Divide the yearly amount of an expense by 12 and multiply weekly amounts by 4.3. Include every expense, as it’s important to determine your lifestyle, equitable distribution and support if any.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-30">
        <form>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Housing </h4>
                <p className="mt-23 mb-27 paragraph">Costs Associated with home ownership, rent, others</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="housingCost"
                  value={formik.values.housingCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.housingCost && formik.errors.housingCost && (
                  <div className="text-danger">{formik.errors.housingCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Utilities </h4>
                <p className="mt-23 mb-27 paragraph">Telephone, internet, electric, gas, etc. </p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="utilitiesCost"
                  value={formik.values.utilitiesCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.utilitiesCost && formik.errors.utilitiesCost && (
                  <div className="text-danger">{formik.errors.utilitiesCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Food</h4>
                <p className="mt-23 mb-27 paragraph">Groceries, dining out, take out, others.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="foodCost"
                  value={formik.values.foodCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.foodCost && formik.errors.foodCost && (
                  <div className="text-danger">{formik.errors.foodCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Clothing</h4>
                <p className="mt-23 mb-27 paragraph">Suits, Office Wear, Causals, Others.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="clothingCost"
                  value={formik.values.clothingCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.clothingCost && formik.errors.clothingCost && (
                  <div className="text-danger">{formik.errors.clothingCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Insurance</h4>
                <p className="mt-23 mb-27 paragraph">Medical, Dental, Liability, Personal Articles, Disability</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="insuranceCost"
                  value={formik.values.insuranceCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.insuranceCost && formik.errors.insuranceCost && (
                  <div className="text-danger">{formik.errors.insuranceCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Unreimbursed medical</h4>
                <p className="mt-23 mb-27 paragraph">Medical, Dental, Liability, Personal Articles, Disability</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="unReimbursedMedicalCost"
                  value={formik.values.unReimbursedMedicalCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.unReimbursedMedicalCost && formik.errors.unReimbursedMedicalCost && (
                  <div className="text-danger">{formik.errors.unReimbursedMedicalCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Maintenance</h4>
                <p className="mt-23 mb-27 paragraph">Repairs, Sanitation, Extermination, Other</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="maintenanceCost"
                  value={formik.values.maintenanceCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.maintenanceCost && formik.errors.maintenanceCost && (
                  <div className="text-danger">{formik.errors.maintenanceCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Household help</h4>
                <p className="mt-23 mb-27 paragraph">Nanny, Babysitter, Housekeeper, etc.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="householdHelpCost"
                  value={formik.values.householdHelpCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.householdHelpCost && formik.errors.householdHelpCost && (
                  <div className="text-danger">{formik.errors.householdHelpCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Automobile</h4>
                <p className="mt-23 mb-27 paragraph">Car, Sports cars, Offices Vehicle, etc.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="automobileCost"
                  value={formik.values.automobileCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.automobileCost && formik.errors.automobileCost && (
                  <div className="text-danger">{formik.errors.automobileCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Education</h4>
                <p className="mt-23 mb-27 paragraph">School, School Supplies, Others.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="educationCost"
                  value={formik.values.educationCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.educationCost && formik.errors.educationCost && (
                  <div className="text-danger">{formik.errors.educationCost}</div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Recreational</h4>
                <p className="mt-23 mb-27 paragraph">Music, Performance, Summer, Vacations</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="recreationalCost"
                  value={formik.values.recreationalCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.recreationalCost && formik.errors.recreationalCost && (
                  <div className="text-danger">{formik.errors.recreationalCost}</div>
                )}
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Income Taxes</h4>
                <p className="mt-23 mb-27 paragraph">Federal, State, City, Social Security And Medicare.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="incomeTaxesCost"
                  value={formik.values.incomeTaxesCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.incomeTaxesCost && formik.errors.incomeTaxesCost && (
                  <div className="text-danger">{formik.errors.incomeTaxesCost}</div>
                )}
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mt-17 heading-4">Miscellaneous</h4>
                <p className="mt-23 mb-27 paragraph">Federal, State, City, Social Security And Medicare.</p>
              </div>
              <div>
                <input
                  type="number"
                  className="input-styling input-margin-right mt-40"
                  name="miscellaneousCost"
                  value={formik.values.miscellaneousCost}
                  onChange={handleChange}
                  onBlur={handleChange}
                  onKeyUp={calculateTotal}
                />
                {formik.touched.miscellaneousCost && formik.errors.miscellaneousCost && (
                  <div className="text-danger">{formik.errors.miscellaneousCost}</div>
                )}
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div className="d-flex justify-content-between align-items-center mb-27">
              <div>
                <h4 className="mb-0 mt-17 heading-4">Total</h4>
              </div>
              <div>
                <input type="number"
                  name="totalCost"
                  value={formik.values.totalCost}
                  readOnly className="input-styling input-margin-right mt-40" />
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-end order-lg-last order-md-last order-sm-first">
              <button className="btn btn-size btn-secondary mb-sm-10 me-2" type="submit" onClick={handleSubmit}>Save</button>
              <button className="btn btn-size btn-primary" type="button" onClick={handleContinue}>Continue</button>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-center mb-3 mb-sm-0">
              <Link to="/income" className="nav-link"><i className="fa fa-chevron-left mt-3 me-2"></i> Back</Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MonthlyExpenses;
