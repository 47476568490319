import React, { useEffect, useState } from "react";
import PlanDetails from "./PlanDetails";
import PlanPrice from "./PlanPrice";
import { isEmpty, size } from "lodash";
import { PlansService } from "../../../services/plans.service";
import { useParams } from "react-router-dom";
import { PLAN_TYPES } from "../../../constants/plan.constants";
import "./checkout.css";

const { BASIC } = PLAN_TYPES;

const ProductDetails = (props) => {
  const { planId } = useParams();
  const { setCouponDetails } = props;

  const [promoCode, setPromocode] = useState("");
  const [isLoading, setIsLoading] = useState('');
  const [couponResult, setCouponResult] = useState(null);
  const [couponError, setCouponError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPromotioncode, setShowPromotioncode] = useState(false);

  const planService = new PlansService();

  const verifyCoupon = async () => {
    setIsLoading(true);
    try {
      const { data } = await planService.verifyCoupon(promoCode);
      if(!isEmpty(data?.data)) {
        setCouponResult(data?.data);
        setIsLoading(false);
      }
      console.log('coupon-data', data);
    } catch(err) {
      setIsLoading(false);
      setCouponError(err.response?.data?.message);
      console.log('coupon err', err.response?.data?.message);
    }
  }

  useEffect(() => {
    const plans = JSON.parse(localStorage.getItem('plans')??'[]');
    if(!isEmpty(plans) && planId) {
      const selectedP = plans?.find(el=>el._id == planId);
      setSelectedPlan(selectedP)
    }
  }, [planId])

  console.log('selectredPlan', selectedPlan);

  useEffect(() => {
    if(!isEmpty(couponResult)) {
      setCouponResult(couponResult);
    } else if(isEmpty(couponResult)) {
      setCouponResult(null);
    }
  }, [couponResult])

  let discountedValue;
  const isBasic = selectedPlan?.type == BASIC;
  const subTotalvalue = isBasic ? 116:392;


  if(couponResult?.percent_off) {
    const dicountAmount = (subTotalvalue * (couponResult?.percent_off/100));
    discountedValue =  parseFloat(subTotalvalue - dicountAmount).toFixed(2);
  }

  console.log('couponResult', couponResult);

  return (
    <div className="col-lg-4 mt-5">
      {/* <p className="color-anew anew-h">Anew</p> */}
      <p className="anew-dim anew-h1">{isBasic ? 'Try Anew':'Start Anew'}</p>
      <p className="color-anew price-h1 mb-0">{isBasic ? '7 days free':'$392.00'}</p>
      <p className="anew-dim anew-h2 mt-0">Then $116.00 per year</p>
      {!isBasic && (
          <PlanDetails
          title="Anew Financial Discovery Guide"
          img="/assets/img/discovery-guide.png"
          price="$392.00"
          description="For less than a coffee a day, find hidden accounts, prove wasteful spending and optimize your outcome in the divorce"
        />
      )}
      <PlanDetails
        title="Anew Basic plan"
        price="7 days free"
        price2="$116.00 / year after"
        img="/assets/img/anew-img.png"
        description="For less than $10 a month, get all your financials organized & plan for your settlement."
      />
      <p className="color-anew anew-price2 mb-0">{"$116.00 / year after"}</p>
      <div className="row">
        <div className="col-1" />
        <div className="col-11">
          <div className="my-3 border-line"></div>
          <PlanPrice title="Subtotal" price={`$${subTotalvalue}`} />
          <div className="cursor-pointer" onClick={() =>{
              if(showPromotioncode) {
                setCouponResult(null);
                setShowPromotioncode(false);
                setCouponError(null);
                setCouponDetails(null);
              } else if (!showPromotioncode) {
                setShowPromotioncode(true);
                setCouponResult(null);
                setCouponError(null);
                setCouponDetails(null);
              }
            }}>
            <p className="blue-color">{!showPromotioncode ? 'Add promotion code' : 'Cancel Code'}</p>
          </div>
          {
            showPromotioncode && (
              <div className="d-flex position-relative">
              <input
                type="text"
                onChange={(e) => {setPromocode(e.target.value);setCouponError('')}}
                className="form-control"
                placeholder="Add promotion code"
              />
              {size(promoCode) > 0 && (
                <>
                <button
                  disabled={isLoading}
                  onClick={verifyCoupon}
                  style={{ color: "#0074d4", fontWeight: "500" }}
                  className="btn position-absolute end-0"
                >
                  Apply
                </button>
                </>
              )}
            </div>)
          }
          <div className="text-danger">{couponError}</div>
          <div className="mt-4">
            <PlanPrice title="Total due today" price={couponResult && couponResult?.percent_off ? `$${discountedValue}` : `$${subTotalvalue}`} />
          </div>
        </div>
      </div>
      <div className="col-lg-8 d-flex"></div>
    </div>
  );
};
export default ProductDetails;
