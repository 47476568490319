import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class OnboardService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/onboard";
  }

  createOrUpdateOnboard(data) {
    return this.post(this.apiUrl, data);
  }
 
  update(data) {
    return this.post(this.apiUrl + "/update", data);
  }

  getOnboardingDetails(id) {
    return this.get(this.apiUrl + "/" + id);
  }
}

