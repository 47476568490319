import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home.jsx";
import Income from "./pages/income/Income";
import MonthlyExpenses from "./pages/Montly-Expenses.jsx";
import Assets from "./pages/assets/Assets.jsx";
import Settlement from "./pages/settelment/settelment.jsx";
import SettlementCalculation from "./pages/settelment/settlementCalculation.jsx";
import Liabilities from "./pages/liabilities/Liabilities.jsx";
import OnBoardOptions from "./pages/onboard/onboard/OnBoard.jsx";
import CohabilitationStepOne from "./pages/onboard/cohabilitation/CohabilitationStepOne.jsx";
import CohabilitationStepTwo from "./pages/onboard/cohabilitation/CohabilitationStepTwo.jsx";
import DivorceStepOne from "./pages/onboard/divorce/DivorceStepOne.jsx";
import DivorceStepTwo from "./pages/onboard/divorce/DivorceStepTwo.jsx";
import PrenupStepOne from "./pages/onboard/prenup/PrenupStepOne.jsx";
import PrenupStepTwo from "./pages/onboard/prenup/PrenupStepTwo.jsx";
import OtherStepOne from "./pages/onboard/other/OtherStepOne.jsx";
import Confirmation from "./pages/onboard/confirmation/Confirmation.jsx";
import { ROUTES } from "./routes/routes";
import Plans from "./pages/plans/plans/Plans.jsx";
import PaymentCheckout from "./pages/plans/checkout/Checkout.jsx";
import User from './pages/user/profile.jsx';
import Trial from "./pages/trial/Trial.jsx";

const {
  TRIAL,
  
  ONBOARD,
  DIVORCE_STEP_ONE,
  DIVORCE_STEP_TWO,
  PRENUP_STEP_ONE,
  PRENUP_STEP_TWO,
  COHABILITATION_STEP_ONE,
  COHABILITATION_STEP_TWO,
  OTHER_STEP_ONE,
  CONFIRMATION,
  CHECKOUT_PLANID,
  PLANS,
  HOME,

  INCOME,
  ASSETS,
  LIABILITIES,
  SETTLEMENT,
  SETTLEMENT_ID,
  CHANGE_PROFILE,
  MONTHLY_EXPENSE,
  SETTLEMENT_CALCULATOR,
} = ROUTES;

function AppRouter() {
  
  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={INCOME} element={<Income />} />
      {/* OnBoarding Routes */}
      <Route path={ONBOARD} element={<OnBoardOptions />} />

      <Route path={DIVORCE_STEP_ONE} element={<DivorceStepOne />} />
      <Route path={DIVORCE_STEP_TWO} element={<DivorceStepTwo />} />

      <Route path={PRENUP_STEP_ONE} element={<PrenupStepOne />} />
      <Route path={PRENUP_STEP_TWO} element={<PrenupStepTwo />} />

      <Route path={COHABILITATION_STEP_ONE} element={<CohabilitationStepOne />} />
      <Route path={COHABILITATION_STEP_TWO} element={<CohabilitationStepTwo />} />

      <Route path={OTHER_STEP_ONE} element={<OtherStepOne />} />
      <Route path={CONFIRMATION} element={<Confirmation />} />

      <Route path={TRIAL} element={<Trial />} />

      {/* End of OnBoarding */}
      {/* Payment Routes */}

      <Route path={PLANS} element={<Plans />} />
      <Route path={CHECKOUT_PLANID} element={<PaymentCheckout />} />

      {/* End of payment Routes */}
      <Route path={LIABILITIES} element={<Liabilities />} />
      <Route path={MONTHLY_EXPENSE} element={<MonthlyExpenses />} />
      <Route path={ASSETS} element={<Assets />} />
      <Route path={SETTLEMENT} element={<Settlement />} />
      <Route path={SETTLEMENT_ID} element={<Settlement />} />
      <Route
        path={SETTLEMENT_CALCULATOR}
        element={<SettlementCalculation />}
      />
      <Route path={CHANGE_PROFILE} element={<User />} />
    </Routes>
  );
}

export default AppRouter;
