import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import "./onboard.css";
import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "../../../routes/routes";
import { OnboardService } from "../../../services/onboardService";
import { ONBOARD_TYPES } from '../constants';
import { isEmpty } from "lodash";
import { UserService } from "../../../services/User.service";

const { DIVORCE, COHABILITATION, PRENUP, OTHER } = ONBOARD_TYPES;
const { COHABILITATION_STEP_ONE, PRENUP_STEP_ONE, OTHER_STEP_ONE, DIVORCE_STEP_ONE } = ROUTES;

const OnBoard = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  
  console.log('user', user);

  const userService = new UserService();
  const onboardService = new OnboardService();

  const getUser = async () => {
    try {
      const response = await userService.getUser(user.id);
      console.log('response', response);
      setUserData(response.data)
      if (!response.data) {
        const data = {
          fName: user.firstName,
          lName: user.lastName,
          id: user.id,
          email: user.emailAddresses[0].emailAddress
        };
        let userDatat = await userService.create(data);
        setUserData(userDatat.data)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    getUser();
    console.log(user);
  }, [])

  const clickHandler = async (path, type) => {
    try {
      const payload = { userId: user?.id, type };
      if(user?.emailAddresses[0]?.emailAddress) {
        payload.email = user?.emailAddresses[0]?.emailAddress;
      }
      const { data } = await onboardService.createOrUpdateOnboard(payload);
      if(!isEmpty(data?.data)) {
        localStorage.setItem('onboardDetails', JSON.stringify(data.data));
        navigate(path);
      }
    } catch(err) {

    }

  }

  return (
    <>
      <ToastContainer />
      <div className="onboard-background">
        <img className="m-4" src="assets/img/logo.svg" />
        {/* <h1 className="onboard-heading m-0 pt-4">Anew</h1> */}
        <div className="avenue-onboard"></div>
        <div className="container py-5">
          <h1 className="heading-2">Let's chart a new path</h1>
          <h1 className="heading-3 text-center pt-4">I'm here to figure out</h1>
          <div className="row py-5">
            <div className="col-3 cursor-pointer" onClick={() => clickHandler(COHABILITATION_STEP_ONE, COHABILITATION)}>
              <div className="card py-4 border-rounded">
                <h3 className="card-text py-5 text-center">cohabitation</h3>
              </div>
            </div>
            <div className="col-3 cursor-pointer"  onClick={() => clickHandler(PRENUP_STEP_ONE, PRENUP)}>
              <div className="card py-4 border-rounded">
                <h3 className="card-text py-5 text-center">prenup</h3>
              </div>
            </div>
            <div className="col-3 cursor-pointer" onClick={() => clickHandler(DIVORCE_STEP_ONE, DIVORCE)}>
              <div className="card py-4 border-rounded">
              <h3 className="card-text py-5 text-center">divorce</h3>
              </div>
            </div>
            <div className="col-3 cursor-pointer" onClick={() => clickHandler(OTHER_STEP_ONE, OTHER)}>
              <div className="card py-4 border-rounded">
                <h3 className="card-text py-5 text-center">other</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnBoard;
