import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";
import "./settlement.css";
import { AssetsService } from "../../services/asset.service";
import { SettlementService } from "../../services/settlement.Service";
import { LiabilitiesService } from "../../services/liabilities.service";


const SettlementCalculation = () => {
  const location = useLocation();
  const { user } = useUser();

  const assetsService = new AssetsService();
  const liabilitiesService = new LiabilitiesService();
  const settlementService = new SettlementService();
  const [purchasePrice, setPurchasePrice] = useState("");
  const [settlementRecordId, setSettlementRecordId] = useState("");
  const [mortgageBalance, setMortgageBalance] = useState("");
  const [equity, setEquity] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [yourSeparateProperty, setYourSeparateProperty] = useState("");
  const [spouseSeparateProperty, setSpouseSeparateProperty] = useState("");
  const [yourSplitPercentage, setYourSplitPercentage] = useState("");
  const [
    totalMaritalEquityUpForDistribution,
    setTotalMaritalEquityUpForDistribution,
  ] = useState("");
  const [yourSplit, setYourSplit] = useState("");
  const [yourTotalProperty, setYourTotalProperty] = useState("");
  const [brokerFee, setBrokerFee] = useState("");
  const [stateTax, setStateTax] = useState("");
  const [cityTax, setCityTax] = useState("");
  const [capitalTax, setCapitalTax] = useState("");
  const [ballanceLeftOnMortgage, setBallanceLeftOnMortgage] = useState("");
  const [refinancingFee, setRefinancingFee] = useState("");
  const [totalSellingCost, setTotalSellingCost] = useState("");
  const [mics, setMics] = useState("");
  const [maritalAssets, setMaritalAssets] = useState("");
  const [maritalDebits, setMaritalDebits] = useState("");
  const [calculatedBrokerFee, setCalculatedBrokerFee] = useState("");
  const [calculatedStateTax, setCalculatedStateTax] = useState("");
  const [calculatedCityTax, setCalculatedCityTax] = useState("");
  const [calculatedCapitalTax, setCalculatedCapitalTax] = useState("");
  const [calculatedMics, setCalculatedMics] = useState("");
  const [netProceeds, setNetProceeds] = useState("");
  const [refiRate, setRefiRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [newMonthlyPayment, setNewMonthlyPayment] = useState("");
  const [you, setYou] = useState(0);
  const [spouse, setSpouse] = useState(0);
  const [youDebit, setYouDebit] = useState(0);
  const [spouseDebit, setSpouseDebit] = useState(0);
  const [yourPotentialAmount, setYourPotentialAmount] = useState(0);
  const [yourPotentialAmountDebit, setYourPotentialAmountDebit] = useState(0);

  const calculateYourPotentialAmount = () => {
    const assets = parseFloat(maritalAssets.replace(/,/g, ''));
    const contribution = parseInt(you);
    if (contribution === 0) {
      alert("Contribution cannot be zero.");
      return;
    }

    const amount = (assets * contribution) / 100;

    setYourPotentialAmount(toHumanReadableNumber(amount));
  };

  const calculateYourPotentialAmountDebit = () => {
    const assets = parseFloat(maritalDebits.replace(/,/g, ''));

    const contribution = parseInt(youDebit);
    if (contribution === 0) {
      alert("Contribution cannot be zero.");
      return;
    }

    const amount = (assets * contribution) / 100;
    setYourPotentialAmountDebit(toHumanReadableNumber(amount));
  };

  const handleCalculations = () => {
    if (!purchasePrice || !mortgageBalance || !currentValue || !yourSeparateProperty || !spouseSeparateProperty) {
      return;
    }

    const numPurchasePrice = parseFloat(purchasePrice);
    const numMortgageBalance = parseFloat(mortgageBalance);
    const numCurrentValue = parseFloat(currentValue);
    const numYourSeparateProperty = parseFloat(yourSeparateProperty);
    const numSpouseSeparateProperty = parseFloat(spouseSeparateProperty);
    const numYourSplit = parseFloat(yourSplit);
    const numBrokerFee = parseFloat(brokerFee);
    const numStateTax = parseFloat(stateTax);
    const numCityTax = parseFloat(cityTax);
    const numCapitalTax = parseFloat(capitalTax);
    const numMics = parseFloat(mics);
    const numBallanceLeftOnMortgage = parseFloat(ballanceLeftOnMortgage);

    const calculatedEquity = (((numCurrentValue - numMortgageBalance) / numCurrentValue)) * 100;
    setEquity(toHumanReadableNumber(calculatedEquity));

    const totalMaritalEquity = numCurrentValue - numMortgageBalance - numYourSeparateProperty - numSpouseSeparateProperty;
    setTotalMaritalEquityUpForDistribution(toHumanReadableNumber(totalMaritalEquity))

    const yourSplitPercentage = (totalMaritalEquity * Number(numYourSplit)) / 100;

    if (isNaN(yourSplitPercentage)) {
      console.error("Invalid calculation for yourSplitPercentage", { totalMaritalEquityUpForDistribution, numYourSplit });
      setYourSplitPercentage("0");
    } else {
      setYourSplitPercentage(toHumanReadableNumber(yourSplitPercentage));
    }

    const maritalEquityPlusInitialProperty = parseFloat(yourSplitPercentage) + parseFloat(numYourSeparateProperty);
    setYourTotalProperty(toHumanReadableNumber(maritalEquityPlusInitialProperty));

    const brokerFeeValue = isNaN(brokerFee) ? 0 : (numBrokerFee * numCurrentValue) / 100;

    const numCalculatedBrokerFee = parseFloat(brokerFeeValue);
    setCalculatedBrokerFee(numCalculatedBrokerFee)

    const stateTaxValue = isNaN(stateTax) ? 0 : (numStateTax * numCurrentValue) / 100;
    const numCalculatedStateTax = parseFloat(stateTaxValue);
    setCalculatedStateTax(numCalculatedStateTax)

    const cityTaxValue = isNaN(cityTax) ? 0 : (numCityTax * numCurrentValue) / 100;
    const numCalculatedCityTax = parseFloat(cityTaxValue);
    setCalculatedCityTax(numCalculatedCityTax)

    const capitalTaxValue = ((Number(numCurrentValue) - Number(numPurchasePrice)) * Number(numCapitalTax)) / 100;
    const numCalculatedCapitalTax = parseFloat(capitalTaxValue);
    setCalculatedCapitalTax(numCalculatedCapitalTax);

    const micsValue = isNaN(mics) ? 0 : (numMics);
    setCalculatedMics(micsValue);

    const sellingCost = parseFloat(numCalculatedBrokerFee) + parseFloat(numCalculatedStateTax) + parseFloat(numCalculatedCityTax) + parseFloat(numCalculatedCapitalTax) + parseFloat(numMics)
    console.log(yourSplitPercentage, sellingCost, yourSeparateProperty);
    const netProceedsSplit = (parseFloat(yourSplitPercentage) - parseFloat(sellingCost)) + parseFloat(yourSeparateProperty);
    setNetProceeds(toHumanReadableNumber(netProceedsSplit));

  };
  const calculateNewMonthlyPayment = () => {
    if (!mortgageBalance || !refiRate || !loanTerm) {
      return;
    }
    const numBallanceLeftOnMortgage = parseFloat(ballanceLeftOnMortgage);
    
    const numRefiRate = parseFloat(refiRate) / 100;
    const numLoanTerm = parseFloat(loanTerm);

    const monthlyInterestRate = numRefiRate / 12;
    const totalPayments = numLoanTerm * 12;


    const numerator = numBallanceLeftOnMortgage * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments);

    const denominator = Math.pow(1 + monthlyInterestRate, totalPayments) - 1;

    const newPayment = numerator / denominator;

    setNewMonthlyPayment(toHumanReadableNumber(newPayment));
  };

  const handleYouChange = (event) => {
    const youValue = parseInt(event.target.value);
    setYou(youValue);
    setSpouse(100 - youValue);
  };

  const handleYouChangeDebit = (event) => {
    const youValueDebit = parseInt(event.target.value);
    setYouDebit(youValueDebit);
    setSpouseDebit(100 - youValueDebit);
  };

  const getLiabilitiesData = async () => {
    let liabilitiesData = await liabilitiesService.getData({ "userId": user.id })
    liabilitiesData = liabilitiesData.data[0]
    if (liabilitiesData) {
      const { creditCard, personalLoan, studentLoan } = liabilitiesData;

      const totalCurrentBalanceCreditCard = calculateTotalBalance(
        creditCard,
        "currentBalance"
      );

      const totalCurrentBalancePersonalLoan = calculateTotalBalance(
        personalLoan,
        "currentBalance"
      );
      const totalCurrentBalanceStudentLoan = calculateTotalBalance(
        studentLoan,
        "currentBalance"
      );

      // Calculate total balance as of marriage commencement date for each category
      const totalMarriageCommencementBalanceCreditCard = calculateTotalBalance(
        creditCard,
        "marriageCommencementBalance"
      );
      const totalMarriageCommencementBalancePersonalLoan =
        calculateTotalBalance(personalLoan, "marriageCommencementBalance");
      const totalMarriageCommencementBalanceStudentLoan = calculateTotalBalance(
        studentLoan,
        "marriageCommencementBalance"
      );

      // Calculate the difference for each category
      const differenceCreditCard =
        totalCurrentBalanceCreditCard -
        totalMarriageCommencementBalanceCreditCard;
      const differencePersonalLoan =
        totalCurrentBalancePersonalLoan -
        totalMarriageCommencementBalancePersonalLoan;
      const differenceStudentLoan =
        totalCurrentBalanceStudentLoan -
        totalMarriageCommencementBalanceStudentLoan;

      const yourTotalDebits =
        differenceCreditCard + differencePersonalLoan + differenceStudentLoan;

      setMaritalDebits(toHumanReadableNumber(yourTotalDebits));
    }
  };

  const calculateTotalBalance = (dataArray, key) => {
    return dataArray.reduce(
      (total, item) => total + parseInt(item[key] || 0),
      0
    );
  };

  const fetchCalculationData = async () => {
    try {
      let calculationData = await settlementService.getData({ "userId": user.id });
      if (calculationData && calculationData.data && calculationData.data.length > 0) {
        const data = calculationData.data[0];
        setSettlementRecordId(data?._id);
        setPurchasePrice(data?.purchasePrice);
        setMortgageBalance(data?.mortgageBalance);
        setCurrentValue(data?.currentValue);
        setYourSplit(data?.yourSplitPercentage);
        setYourSeparateProperty(data?.yourSeparateProperty);
        setSpouseSeparateProperty(data?.spouseSeparateProperty);
        setBrokerFee(data?.brokerFee);
        setStateTax(data?.stateTax);
        setCapitalTax(data?.capitalTax);
        setCityTax(data?.cityTax);
        setMics(data?.mics);
        setBallanceLeftOnMortgage(data?.ballanceLeftOnMortgage);
        setRefiRate(data?.refiRate);
        setLoanTerm(data?.loanTerm);
        setRefinancingFee(data?.refinancingFee);

        const calculatedEquity = (((data?.currentValue - data?.mortgageBalance) / data?.currentValue)) * 100;
        setEquity(toHumanReadableNumber(calculatedEquity));
        const totalMaritalEquity = data?.currentValue - data?.mortgageBalance - data?.yourSeparateProperty - data?.spouseSeparateProperty;
        setTotalMaritalEquityUpForDistribution(toHumanReadableNumber(totalMaritalEquity))

        const yourSplitPercentage = (totalMaritalEquity * Number(data.yourSplitPercentage)) / 100;
        setYourSplitPercentage(toHumanReadableNumber(yourSplitPercentage));

        const maritalEquityPlusInitialProperty = parseFloat(yourSplitPercentage) + parseFloat(data?.yourSeparateProperty);
        setYourTotalProperty(toHumanReadableNumber(maritalEquityPlusInitialProperty));

        const brokerFeeValue = (data?.brokerFee * data?.currentValue) / 100;

        const numCalculatedBrokerFee = parseFloat(brokerFeeValue);
        setCalculatedBrokerFee(numCalculatedBrokerFee)

        const stateTaxValue = (data?.stateTax * data?.currentValue) / 100;
        const numCalculatedStateTax = parseFloat(stateTaxValue);
        setCalculatedStateTax(numCalculatedStateTax)

        const cityTaxValue = (data.cityTax * data?.currentValue) / 100;
        const numCalculatedCityTax = parseFloat(cityTaxValue);
        setCalculatedCityTax(numCalculatedCityTax)

        const capitalTaxValue = ((Number(data?.currentValue) - Number(data?.purchasePrice)) * Number(data?.capitalTax)) / 100;
        const numCalculatedCapitalTax = parseFloat(capitalTaxValue);
        setCalculatedCapitalTax(numCalculatedCapitalTax);

        setCalculatedMics(data?.mics);

        const sellingCost = parseFloat(numCalculatedBrokerFee) + parseFloat(numCalculatedStateTax) + parseFloat(numCalculatedCityTax) + parseFloat(numCalculatedCapitalTax) + parseFloat(data?.mics)
        console.log(data?.yourSplitPercentage, sellingCost, yourSeparateProperty);
        const netProceedsSplit = (parseFloat(yourSplitPercentage) - parseFloat(sellingCost)) + parseFloat(data?.yourSeparateProperty);
        setNetProceeds(toHumanReadableNumber(netProceedsSplit));

        const numBallanceLeftOnMortgage = parseFloat(data?.ballanceLeftOnMortgage);
        const numRefiRate = parseFloat(data?.refiRate) / 100;
        const numLoanTerm = parseFloat(data?.loanTerm);

        const monthlyInterestRate = numRefiRate / 12;
        const totalPayments = numLoanTerm * 12;


        const numerator = numBallanceLeftOnMortgage * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments);

        const denominator = Math.pow(1 + monthlyInterestRate, totalPayments) - 1;

        const newPayment = numerator / denominator;

        setNewMonthlyPayment(toHumanReadableNumber(newPayment));
      }
    } catch (error) {
      console.error("Error fetching calculation data:", error);
    }
  };

  const fetchData = async () => {
    let assetsData = await assetsService.getData({ "userId": user.id })
    if (!assetsData) {
      return;
    }
    const assetsDataParsed = assetsData.data[0];
    const accountAssetsTotal = assetsDataParsed.account.reduce(
      (acc, curr) => acc + (Number(curr.currentBalance) || 0),
      0
    );

    const accountMarriageCommencementBalanceTotal =
      assetsDataParsed.account.reduce(
        (acc, curr) => acc + (Number(curr.marriageCommencementBalance) || 0),
        0
      );


    const investmentAssetsTotal = assetsDataParsed.investment.reduce(
      (acc, curr) => acc + (Number(curr.currentBalance) || 0),
      0
    );

    const investmentMarriageCommencementBalanceTotal =
      assetsDataParsed.investment.reduce(
        (acc, curr) => acc + (Number(curr.marriageCommencementBalance) || 0),
        0
      );


    const retirementAssetsTotal = assetsDataParsed.retirement.reduce(
      (acc, curr) => acc + (Number(curr.currentBalance) || 0),
      0
    );

    const retirementMarriageCommencementBalanceTotal =
      assetsDataParsed.retirement.reduce(
        (acc, curr) => acc + (Number(curr.marriageCommencementBalance) || 0),
        0
      );

    const totalMaritalStatus = (accountAssetsTotal + investmentAssetsTotal + retirementAssetsTotal) -
      (accountMarriageCommencementBalanceTotal + investmentMarriageCommencementBalanceTotal + retirementMarriageCommencementBalanceTotal);
    setMaritalAssets(toHumanReadableNumber(totalMaritalStatus));
  };

  const toHumanReadableNumber = (value) => {
    if (!value) {
      return "";
    }

    let options = { style: 'decimal', maximumFractionDigits: 2 };
    return value.toLocaleString('en-US', options);
  }
  const prepareDataForAPI = () => {

    const data = {
      userId: user.id,
      purchasePrice: purchasePrice,
      mortgageBalance: mortgageBalance,
      currentValue: currentValue,
      yourSeparateProperty: yourSeparateProperty,
      spouseSeparateProperty: spouseSeparateProperty,
      yourSplitPercentage: yourSplit,
      brokerFee: brokerFee,
      stateTax: stateTax,
      cityTax: cityTax,
      capitalTax: capitalTax,
      mics: mics,
      ballanceLeftOnMortgage: ballanceLeftOnMortgage,
      refiRate: refiRate,
      loanTerm: loanTerm,
      refinancingFee: refinancingFee,
    };

    return data;
  };

  const submitDataToAPI = async (dataForApi) => {
    try {
      if (settlementRecordId) {
        await settlementService.update(settlementRecordId, dataForApi);
        return
      }
      await settlementService.create(dataForApi);
    } catch (error) {
      console.error('Failed to submit data:', error);
      throw error; // re-throw the error for further handling if necessary
    }
  }

  const handleCalculateButtonClick = () => {
    handleCalculations();
    calculateNewMonthlyPayment();
    const apiData = prepareDataForAPI();
    submitDataToAPI(apiData);
  };



  useEffect(() => {
    fetchData();
    if (maritalAssets && you && parseInt(you) !== 0) {
      calculateYourPotentialAmount();
    }
  }, [maritalAssets, you]);

  useEffect(() => {
    getLiabilitiesData();
    if (maritalAssets && youDebit && parseInt(youDebit) !== 0) {
      calculateYourPotentialAmountDebit();
    }
  }, [maritalAssets, youDebit]);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    fetchCalculationData();
  }, []);

  return (
    <>
    <div className="container settlement mb-5 p-4 pt-1 pb-0">
      <div className="row d-flex box justify-content-between p-3">
      <h4 className="mt-1 fw-bold text-center">How to use the settlement planning tools</h4>
      <p>
          The purpose of the settlement planning tools is to enable you to explore different “what if” scenarios to craft your negotiation strategy.</p>
        <p>
          • <b>Asset/Debt Distribution Planner:</b> enter different split percentages you’d like to propose to see what different outcomes could be. Assess the likelihood of achieving these outcomes against the framework listed on the right. Use both numbers and information to identify potential trade-off areas.
        </p>
        <p>
          • <b>Community vs. Equitable Distribution State:</b> Forty-one states and Washington, D.C. are equitable distribution states, which means that a court will divide property in a way that is fair under the circumstances. The remaining nine states Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington, and Wisconsin are community states, where generally both spouses can make an equal ownership claim to all income and assets acquired during the marriage. A couple can always deviate from the state jurisdiction and determine how they want to split up the assets and/or debts through settlement negotiations.
          The settlement planning tools here are the baseline for your case strategy. For full guidance on financial discovery, please refer to our e-guide.</p>
          <a className="d-flex justify-content-center" style={{color:'#fff', textDecoration:'none'}} href="https://www.loveanew.co/financial-discovery-guide"><button className="btn btn-primary">Financial Discovery Guide</button></a>
      </div>
      </div>

      {/* section-1 asset distribution planner*/}
      <div id="distributionPlanner" className="container settlement p-4 pt-1 pb-0">
        <div className="row d-flex box justify-content-between p-3">
          <div className="col-lg-4 asset-distribution-planner">
            <div className="asset-distribution-planner-heading d-flex justify-content-start">
              Asset Distribution Planner
            </div>

            <div className="asset-distribution-planner-potential mt-3">
              Potential Total Marital Assets Up for Distribution
              <div className="asset-distribution-planner-potential-amount mt-3">
                {maritalAssets ? `$${maritalAssets}` : null}
              </div>
            </div>

            <div className="asset-distribution-planner-paragraph mt-3">
              *Please note the above number equals to the balance as of divorce
              filing date/current date minus the balance as of marriage
              commencement date based on your input.  It’s only an estimate.
              Anew’s beta product does not include marital asset
              characterization.
            </div>

            <div className="asset-distribution-planner-percentage mt-3">
              Your proposed split percentage
            </div>
            <div className="row mt-3 debt">
              <div className="col-lg-6 mt-3">
                <div className="custom-input">
                  <label htmlFor="inputYou">You</label>
                  <input
                    type="number"
                    className="custom-input-credit py-3 px-2"
                    id="inputYou"
                    value={you}
                    onChange={handleYouChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 mt-3">
                <div className="custom-input">
                  <label htmlFor="inputSpouse">Your Spouse</label>
                  <input
                    type="number"
                    className="custom-input-credit py-3 px-2"
                    id="inputSpouse"
                    value={spouse}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="asset-distribution-planner distribution-amount mt-3">
              Your potential distribution amount:
            </div>
            <div className="amount mt-3">
              {yourPotentialAmount ? `$${yourPotentialAmount}` : null}
            </div>
          </div>

          <div className="settlements-factors settlements-factors-border col-lg-8">
            <p className="settlements-factors paragraph">
              When determining how to distribute assets, the court considers
              various factors to ensure a fair outcome. Some of the key factors
              include:
            </p>
            <ul className="settlements-factors  paragraph">
              <li className="li-text">
                <span><b>Duration of the Marriage:</b></span> The length of the marriage is
                a significant factor. Longer marriages may result in a more even
                distribution of assets.
              </li>
              <li>
                <b>Spousal Contributions:</b> The contributions each spouse
                made to the marriage, including financial contributions,
                homemaking, and childcare, are considered.
              </li>
              <li>
                <b>Income and Property of Each Spouse:</b> The financial
                circumstances of each spouse, including their income, earning
                capacity, and separate property, are taken into account.
              </li>
              <li>
                <b>Future Financial Prospects:</b> The court considers the
                future financial prospects of each spouse, including their
                ability to support themselves post-divorce.
              </li>
              <li>
                <b>Health and Age of Each Spouse:</b> The physical and
                emotional health, as well as the age, of each spouse are
                considered in determining the distribution of assets.
              </li>
              <li>
                <b>Custodial Parent's Needs:</b> If there are children
                involved, the custodial parent's needs and the financial impact
                of caring for the children are considered.(if applicable)
              </li>
              <li>
                <b>Tax Consequences:</b> The tax consequences of asset
                distribution may be taken into account to ensure a fair
                division.
              </li>
              <li>
                <b>Wasteful Dissipation of Assets:</b> If one spouse has
                wasted marital assets (e.g., through reckless spending or
                financial misconduct), it may be a factor in distribution.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* section-2 debit distribution planner*/}
      <div className="debit-distribution mt-4">
        <div className="container settlement p-4 pt-0 pb-0">
          <div className="row d-flex justify-content-between box p-3">
            <div className="col-lg-4 asset-distribution-planner">
              <div className="debit-distribution-planner-heading d-flex justify-content-start">
                Liabilities Distribution Planner
              </div>
              <div className="asset-distribution-planner-potential mt-3">
                Potential Total Marital Debts Up For Distribution
                <div className="debit-distribution-planner-potential-amount mt-3">
                  {maritalDebits ? `$${maritalDebits}` : null}
                </div>
              </div>
              <div className="asset-distribution-planner-paragraph mt-3">
                *Please note the above number equals to the balance as of
                divorce filing date/current date minus the balance as of
                marriage commencement date based on your input.  It’s only an
                estimate. Anew’s beta product does not include marital asset
                characterization.
              </div>
              <div className="asset-distribution-planner-percentage mt-3">
                Your proposed split percentage
              </div>
              <div className="row mt-3 debt">
                <div className="col-lg-6 mt-3">
                  <div className=" custom-input">
                    <label htmlFor="inputYou">You</label>
                    <input
                      type="number"
                      className="custom-input-debit py-3 px-2"
                      id="inputYou"
                      value={youDebit}
                      onChange={handleYouChangeDebit}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="custom-input">
                    <label htmlFor="inputSpouse">Your Spouse</label>
                    <input
                      type="number"
                      className="custom-input-debit py-3 px-2"
                      id="inputSpouse"
                      value={spouseDebit}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="asset-distribution-planner distribution-amount mt-3">
                Your potential distribution amount:
              </div>
              <div className="debit-amount mt-3">
                {yourPotentialAmountDebit
                  ? `$${yourPotentialAmountDebit}`
                  : null}
              </div>
            </div>

            <div className="settlements-factors settlements-factors-border col-lg-8">
              <p className="settlements-factors paragraph">
                Thee specifics of debt division can vary widely, but below are
                some general guidelines you can consider:
              </p>
              <ul>
                <li>How much is owed</li>
                <li>Why the debt was acquired</li>
                <li>How the debt was acquired</li>
                <li>Which of the spouses benefited most from the debt</li>
                <li>
                  Which of the spouses is most capable of paying off the debts
                </li>
              </ul>
              Additional factors to take into consideration:
              <ul className="settlements-factors  paragraph">
                <li>
                  <b>Income Disparities:</b> Evaluate the income and earning
                  capacity of each spouse. This may impact the ability to take
                  on certain debts post-divorce.
                </li>
                <li>
                  <b>Contributions to Debt Repayment:</b> Assess each spouse's
                  contributions to debt repayment during the marriage. This may
                  influence how debts are divided.
                </li>
                <li>
                  <b>Credit Scores and Liabilities:</b> Be mindful of the
                  impact on credit scores. Joint debts may still affect both
                  spouses, even if a divorce decree assigns responsibility to
                  one party.
                </li>
                <li>
                  <b>Children and Custody Arrangements:</b> If children are
                  involved, consider their needs. Child support and custody
                  arrangements may affect how debts are divided.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* section-3 Real state*/}
      <div id="homeEquitySplit" className="real-state mt-4">
        <div className="container settlement p-4 pt-1">
          <div
            className="row box p-3 "
            style={{
              borderBottom: "none",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <div className="asset-distribution-planner-heading d-flex justify-content-start">
              <div className="real-state-heading">Home Equity Split Calculator</div>
            </div>
            <p className="realState-subtitle"> Once you’ve plugged all the numbers into the calculator, click “Calculate” to view the results</p>

            <div className="col-lg-3 mt-3 custom-input">
              <legend className="real-state-input-label">Purchase Price</legend>
              <input
                type="number"
                className="custom-input-credit py-3 w-100 real-state-input"
                value={purchasePrice}
                onChange={(e) => setPurchasePrice(e.target.value)}
              />
            </div>
            <div className="col-lg-3 mt-3 custom-input">
              <legend className="real-state-input-label">
                Mortgage Balance
              </legend>
              <input
                type="number"
                className="custom-input-credit py-3 w-100 real-state-input"
                value={mortgageBalance}
                onChange={(e) => setMortgageBalance(e.target.value)}
              />
            </div>
            <div className="col-lg-3 mt-3 d-flex justify-content-center">
              <div>
                <legend className="real-state-input-label">Equity</legend>
                <p className="equity w-100">{equity}%</p>
              </div>
            </div>
            <div className="col-lg-3 mt-3 custom-input">
              <legend className="real-state-input-label">
                Current Value
              </legend>
              <input
                type="number"
                className="custom-input-credit py-3 w-100 real-state-input"
                value={currentValue}
                onChange={(e) => setCurrentValue(e.target.value)}
              />

            </div>
            <div className="col-lg-4 custom-input mt-4">
              <label
                htmlFor="inputSpouse"
                className="real-state-input-label width"
              >
                Your separate property
              </label>
              <input
                type="number"
                className="custom-input-credit py-3 w-100 real-state-input"
                value={yourSeparateProperty}
                onChange={(e) => setYourSeparateProperty(e.target.value)}
              />
            </div>
            <div className="col-lg-4 custom-input mt-4">
              <label
                htmlFor="inputSpouse"
                className="real-state-input-label width sperateSpouse"
              >
                Your spouse’s separate property
              </label>
              <input
                type="number"
                className="custom-input-credit py-3 w-100 real-state-input"
                value={spouseSeparateProperty}
                onChange={(e) => setSpouseSeparateProperty(e.target.value)}
              />
            </div>

            <div className="col-lg-6 mt-3">
              Total marital equity up for distribution
              <div className="real-state-amount">
                {totalMaritalEquityUpForDistribution
                  ? `$${totalMaritalEquityUpForDistribution}`
                  : null}
              </div>
              <p>Your proposed split percentage of the marital equity</p>
              <div className="col-lg-6 mt-3">
                <div className="col-lg-12 custom-input">
                  <label
                    htmlFor="inputSpouse"
                    className="real-state-input-label"
                  >
                    You
                  </label>
                  <input
                    type="text"
                    className="custom-input-credit py-3 w-100 real-state-input"
                    id="inputSpouse"
                    value={yourSplit + "%"}
                    onChange={(e) => setYourSplit(e.target.value.replace("%", ""))}
                    placeholder="Enter percentage"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
            <div className="row col-lg-12 d-flex justify-content-between">
              <div className="col-lg-6 mt-3">
                Your split of marital equity before fees & taxes
                <div className="real-state-amount">
                  {yourSplitPercentage ? `$${yourSplitPercentage}` : null}
                </div>
              </div>
              <div className="col-lg-6 mt-3">
                Your split of marital equity plus your initial separate property
                <div className="real-state-amount">
                  {yourTotalProperty ? `$${yourTotalProperty}` : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Options*/}
      <div className="mb-5">
        <div className="container settlement p-4 pt-0 pb-0">
          <div
            className="row d-flex justify-content-between box p-3"
            style={{
              borderTop: "none",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <div className="col-lg-6">
              <div className="option-heading">
                Option 1: sell the house and split the equity
              </div>
              <div className="option-paragraph mt-3">
                Please input the tax rate applicable in your own state (google
                or search your local gov site)
              </div>

              <div className="row d-flex align-items-center justify-content-evenly mt-3">
                <div className="col-lg-4">
                  <label className="form-label input-label">
                    Broker's fee:
                  </label>
                </div>
                <div className="col-lg-4 d-flex align-items-center">
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option1Input"
                    value={brokerFee}
                    onChange={(e) => setBrokerFee(e.target.value)}
                  />
                  <span className="ms-1 mb-2">%</span>
                </div>
                <div className="col-lg-4 d-flex justify-content-end">
                  {calculatedBrokerFee}
                </div>
              </div>

              <div className="row d-flex align-items-center justify-content-evenly mt-3">
                <div className="col-lg-4">
                  <label className="form-label input-label">
                    State transfer tax:
                  </label>
                </div>
                <div className="col-lg-4 d-flex align-items-center">
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option1Input"
                    id="state-transfer-tax"
                    value={stateTax}
                    onChange={(e) => setStateTax(e.target.value)}
                  />
                  <span className="ms-1 mb-2">%</span>
                </div>
                <div className="col-lg-4 d-flex justify-content-end">
                  {calculatedStateTax}
                </div>
              </div>

              <div className="row d-flex align-items-center justify-content-evenly mt-3">
                <div className="col-lg-4">
                  <label className="form-label input-label">
                    City transfer tax:
                  </label>
                </div>
                <div className="col-lg-4 d-flex align-items-center">
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option1Input"
                    id="city-transfer-tax"
                    value={cityTax}
                    onChange={(e) => setCityTax(e.target.value)}
                  />
                  <span className="ms-1 mb-2">%</span>
                </div>
                <div className="col-lg-4 d-flex justify-content-end">
                  {calculatedCityTax}
                </div>
              </div>

              <div className="row d-flex align-items-center justify-content-between mt-3">
                <div className="col-lg-4">
                  <label className="form-label input-label">
                    Capital gain tax:{" "}
                  </label>
                </div>
                <div className="col-lg-4 d-flex align-items-center">
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option1Input"
                    id="city-transfer-tax"
                    value={capitalTax}
                    onChange={(e) => setCapitalTax(e.target.value)}
                  />
                  <span className="ms-1 mb-2">%</span>
                </div>

                <div className="col-lg-4 d-flex justify-content-end">
                  {calculatedCapitalTax}
                </div>
              </div>

              <div className="row d-flex align-items-center justify-content-between mt-3">
                <div className="col-lg-4">
                  <label className="form-label input-label">
                    Misc. Fees (attorney fees, flip tax to your co-op, etc.)
                  </label>
                </div>

                <div className="col-lg-4 d-flex align-items-center">
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option1Input w-100"
                    id="mics"
                    value={mics}
                    onChange={(e) => setMics(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 d-flex justify-content-end">
                  {calculatedMics}
                </div>
              </div>

              <div className="col-lg-3 mb-5">
                <button
                  className="btn btn-size btn-primary mt-5"
                  onClick={handleCalculateButtonClick}
                >
                  Calculate
                </button>
              </div>
              <div className="option-paragraph">
                *Capital gain tax: 1) You can sell your primary residence and be
                exempt from capital gains taxes on the first $250,000 if you are
                single and $500,000 if married filing jointly.2) This exemption
                is only allowable once every two years.3) You can add your cost
                basis and costs of any improvements that you made to the home to
                the $250,000 if single or $500,000 if married filing jointly.
              </div>
            </div>

            <div className="col-lg-6 settlements-factors-border">
              <div className="option-heading">
                Option 2: you buy out your spouse & refinance
              </div>
              <div className="option-paragraph mt-3">
                Use the fields below to calculate the new estimated monthly
                payment
              </div>
              <div className="row d-flex align-items-center justify-content-start mt-5">
                <div className="d-flex align-items-center mb-3">
                  <label className="form-label input-label me-4 option2Lablewidth">
                    Balance left on mortgage
                  </label>
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option2Input"
                    value={ballanceLeftOnMortgage}
                    onChange={(e) => setBallanceLeftOnMortgage(e.target.value)}
                  />
                </div>
                <div className="d-flex align-items-center mb-3">
                  <label className="form-label input-label me-4 option2Lablewidth">
                    Refinance rate
                  </label>
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option2Input"
                    value={refiRate}
                    onChange={(e) => setRefiRate(e.target.value)}
                  />
                  <span className="ms-1 mb-2">%</span>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <label className="form-label input-label me-4 option2Lablewidth">
                    New loan term
                  </label>
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option2Input"
                    value={loanTerm}
                    onChange={(e) => setLoanTerm(e.target.value)}
                  />
                </div>
                <div className="d-flex align-items-center mb-3">
                  <label className="form-label input-label me-4 option2Lablewidth">
                    Refinancing fees (paid upfront)
                  </label>
                  <input
                    type="number"
                    className="form-control py-3 real-state-input option2Input"
                    value={refinancingFee}
                    onChange={(e) => setRefinancingFee(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-5 justify-content-end">
                <div className="option-paragraph">
                  <p>*The new monthly payment is for fixed term loans and does not include tax, property insurance, HOA fees/maintenance fees, etc.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              Your total split of net proceeds
              <div className="real-state-amount">
                {netProceeds ? `$${netProceeds}` : null}
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              Your new monthly payment
              <div className="real-state-amount">
                {newMonthlyPayment ? `$${newMonthlyPayment}` : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettlementCalculation;
