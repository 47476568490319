import { useUser } from "@clerk/clerk-react";
import { React, useState, useRef, useEffect } from "react";
import "./Assets.css";
import { Formik, FieldArray, Form, ErrorMessage } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { AssetsService } from "../../services/asset.service";

const validationSchema = Yup.object().shape({
  account: Yup.array().of(
    Yup.object().shape({
      financialInstitution: Yup.string().required("Required field."),
      accountNumber: Yup.string().required("Required field."),
      isJoint: Yup.bool().required("Required field."),
      date: Yup.string().required("Required field."),
      marriageCommencementBalance: Yup.string().required("Required field."),
      currentBalance: Yup.number().required("Required field."),
      sourceOfFunds: Yup.string().required("Required field.")
    })
  ),
  investment: Yup.array().of(
    Yup.object().shape({
      financialInstitution: Yup.string().required("Required field."),
      accountNumber: Yup.string().required("Required field."),
      isJoint: Yup.bool().required("Required field."),
      date: Yup.string().required("Required field."),
      marriageCommencementBalance: Yup.string().required("Required field."),
      currentBalance: Yup.number().required("Required field."),
      sourceOfFunds: Yup.string().required("Required field.")
    })
  ),
  retirement: Yup.array().of(
    Yup.object().shape({
      financialInstitution: Yup.string().required("Required field."),
      accountNumber: Yup.string().required("Required field."),
      isJoint: Yup.bool().required("Required field."),
      date: Yup.string().required("Required field."),
      marriageCommencementBalance: Yup.string().required("Required field."),
      currentBalance: Yup.number().required("Required field."),
      sourceOfFunds: Yup.string().required("Required field.")
    })
  ),
});

const initialValues = {
  account: [
    {
      financialInstitution: "",
      accountNumber: "",
      isJoint: "false",
      date: "",
      marriageCommencementBalance: "",
      currentBalance: null,
      sourceOfFunds: "",
    }
  ],
  investment: [],
  retirement: [],
};

const Assets = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const formRef = useRef();
  const assetService = new AssetsService()
  const [accountTotal, setAccountTotal] = useState(0);
  const [investmentTotal, setInvestmentTotal] = useState(0);
  const [retirementTotal, setRetirementTotal] = useState(0);
  const [isRetirementVisible, setIsRetirementVisible] = useState(false);
  const [isInvestmentVisible, setIsInvestmentVisible] = useState(false);
  const [recordId, setRecordId] = useState("");

  const toggleInvestmentVisibility = () => {
    setIsInvestmentVisible(!isInvestmentVisible);
  };
  const toggleRetirementVisibility = () => {
    setIsRetirementVisible(!isRetirementVisible);
  };

  const handleContinue = () => {
    formRef.current.handleSubmit();
    setTimeout(() => {
      if (Object.keys(formRef.current.errors).length === 0) {
        navigate('/liabilities');
      }
    }, 250)
  };

  const onChangeCalculateTotal = () => {
    setTimeout(() => {
      const values = formRef.current.values;
      let accountTotalBalance = 0;
      let investmentTotalBalance = 0;
      let retirementTotalBalance = 0;

      values.account.forEach((account) => {
        accountTotalBalance += parseFloat(account.currentBalance) || 0;
      });
      setAccountTotal(accountTotalBalance);

      values.investment.forEach((investment) => {
        investmentTotalBalance += parseFloat(investment.currentBalance) || 0;
      });
      setInvestmentTotal(investmentTotalBalance);

      values.retirement.forEach((retirement) => {
        retirementTotalBalance += parseFloat(retirement.currentBalance) || 0;
      });
      setRetirementTotal(retirementTotalBalance);
    }, 500);
  }

  const getData = () => {
    assetService.getData({ "userId": user.id })
      .then((response) => {
        if (response.data?.length) {
          formRef.current.setValues(response.data[0]);
          setRecordId(response.data[0]._id);

          if (response.data[0]?.investment?.length) {
            setIsInvestmentVisible(true);
          }

          if (response.data[0]?.retirement?.length) {
            setIsRetirementVisible(true);
          }

          onChangeCalculateTotal();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const onRemove = async () => {
    await onChangeCalculateTotal()
    formRef.current.handleSubmit();
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={(values, { resetForm }) => {
            values.userId = user.id;
            if (recordId) {
              assetService.update(recordId, values)
              toast.success("Assets data Updated");
              return
            }
            assetService.create(values)
            toast.success("Assets data saved");
          }}>
          {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
            <Form noValidate autoComplete="off">
              <div className="border">
                <div className="row justify-content-center">
                  <div className="heading text-center box-heading pt-3">
                    Instructions
                  </div>
                </div>
                <div className="paragraph p-3 box-text">
                  Gather key information on your finances and lay the groundwork for
                  settlement planning by connecting your accounts below. <br />
                  Things to note:<br />
                  <ul>
                    <li><span>Balance as of date of marriage commencement</span> is the
                      amount of your separate property, meaning
                      property(cash/investments/401k) you acquired prior to marriage.</li>
                    <li> The
                      importance of “divorce filing date” and “balance as of divorce
                      filing date”. In many states, the divorce filing marks the end of
                      the acquisition of the marital estate. Income, assets and debts
                      acquired after the filing date will NOT be considered marital
                      property anymore. It also stops the clock on the time for
                      calculation for spousal maintenance (alimony.)</li>
                    <li> Anew does not
                      currently identify the following:
                      <ul>
                        <li><span>Commingling of assets: </span> when
                          separate property and marital property become mixed to such an
                          extent that they are unable to be isolated and identified for
                          purposes of classification and distribution. When this occurs, the
                          commingled asset is transformed into marital property.</li>
                        <li>
                          <span>Active vs.
                            passive appreciation of separate property:</span>
                          <ul>
                            <li> Active appreciation —
                              appreciation because of the efforts of one or both spouses — is
                              generally treated as marital property subject to division. </li>
                            <li>Passive
                              appreciation — attributable to external factors such as inflation,
                              market forces, regulatory changes, and the efforts of others — is
                              usually classified as separate property.</li>
                          </ul>
                        </li>
                      </ul></li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="mt-29 bar">
                  <h5 className="p-2">Checking & Savings</h5>
                </div>
                <div className="box mt-44 ">
                  <div className="mb-23 account-typo">Account</div>
                </div>

                <FieldArray name="account">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.account.length > 0 &&
                        values.account.map((account, index) => (
                          <div className="row box" key={index}>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label" htmlFor={`account.${index}.financialInstitution`}>
                                Financial Institution
                              </label>
                              <input
                                id={`account.${index}.financialInstitution`}
                                name={`account.${index}.financialInstitution`}
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                value={values.account[index].financialInstitution}
                              />
                              <div className="text-danger"><ErrorMessage name={`account.${index}.financialInstitution`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label" htmlFor={`account.${index}.accountNumber`}>
                                Account Number(last four digits)
                              </label>
                              <input
                                id={`account.${index}.accountNumber`}
                                name={`account.${index}.accountNumber`}
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                value={values.account[index].accountNumber}
                              />
                              <div className="text-danger"><ErrorMessage name={`account.${index}.accountNumber`} /></div>
                            </div>
                            <div className="asset-label col-lg-8 d-flex justify-content-between align-items-center mb-0">
                              <label
                                className=" form-check-label asset-label-checkbox"
                                htmlFor={`flexCheckChecked`}
                              >
                                Is this a joint account of you and your spouse?
                              </label>
                              <div className="d-flex">
                                <div className="me-5">
                                  <label htmlFor={`account.${index}.isJoint.yes`}>Yes</label>
                                  <input
                                    id={`account.${index}.isJoint.yes`}
                                    name={`account.${index}.isJoint`}
                                    type="radio"
                                    className="form-check-input ms-1"
                                    value="true"
                                    onChange={handleChange}
                                    checked={values.account[index].isJoint === "true"}
                                  />
                                </div>
                                <div>
                                  <label htmlFor={`account.${index}.isJoint.no`}>No</label>
                                  <input
                                    id={`account.${index}.isJoint.no`}
                                    name={`account.${index}.isJoint`}
                                    type="radio"
                                    className="form-check-input ms-1"
                                    value="false"
                                    onChange={handleChange}
                                    checked={values.account[index].isJoint === "false"}
                                  />
                                </div>
                              </div>
                              <div className="text-danger"><ErrorMessage name={`account.${index}.isJoint`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Date opened</label>
                              <input
                                id={`account.${index}.date`}
                                name={`account.${index}.date`}
                                type="Date"
                                className="form-control"
                                onChange={handleChange}
                                value={values.account[index].date}
                              />
                              <div className="text-danger"><ErrorMessage name={`account.${index}.date`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Balance as of date of marriage commencement</label>
                              <input
                                id={`account.${index}.marriageCommencementBalance`}
                                name={`account.${index}.marriageCommencementBalance`}
                                type="number"
                                className="form-control"
                                onChange={handleChange}
                                value={values.account[index].marriageCommencementBalance}
                              />
                              <div className="text-danger"><ErrorMessage name={`account.${index}.marriageCommencementBalance`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Current balance or balance as of divorce filing date</label>
                              <input
                                id={`account.${index}.currentBalance`}
                                name={`account.${index}.currentBalance`}
                                type="number"
                                className="form-control"
                                onChange={(data) => { handleChange(data); onChangeCalculateTotal(); }}
                                value={values.account[index].currentBalance}
                              />
                              <div className="text-danger"><ErrorMessage name={`account.${index}.currentBalance`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Source of Funds</label>
                              <select
                                id={`account.${index}.sourceOfFunds`}
                                name={`account.${index}.sourceOfFunds`}
                                className="form-control"
                                onChange={handleChange}
                                value={values.account[index].sourceOfFunds}
                              >
                                <option value="">Select Source</option>
                                <option value="Separate Income/Property">Separate Income/Property</option>
                                <option value="Marital Income/Property">Marital Income/Property</option>
                                <option value="Other">Other</option>
                              </select>
                              <div className="text-danger"><ErrorMessage className="error" name={`account.${index}.sourceOfFunds`} /></div>
                            </div>

                            {values.account.length > 1 && (
                              <div className="col-sm-12 mb-3 text-center">
                                <button type="button" className="btn btn-size btn-secondary mb-2" onClick={() => {remove(index);onRemove()}}>
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      <div className="row">
                        <div className="col-sm-12 my-3 text-center">
                          <button
                            className="btn btn-size btn-primary"
                            type="button"
                            onClick={() =>
                              push({
                                financialInstitution: "",
                                accountNumber: "",
                                isJoint: "false",
                                date: "",
                                marriageCommencementBalance: "",
                                currentBalance: 0,
                                sourceOfFunds: "",
                              })
                            }
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <div className="row d-flex mt-26 justify-content-end">
                  <div className="d-flex col-lg-4 align-items-center justify-content-end ">
                    <div className="label asset-label">
                      <label className="form-label pb-3 px-3">Total</label>
                    </div>
                    <div className="input">
                      <input className="mb-1-total form-control" value={accountTotal} readOnly />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-30 bar" onClick={toggleInvestmentVisibility}>
                <div className="container-fluid">
                  <h5 className="p-2">Investments</h5>
                </div>
              </div>
              {isInvestmentVisible && (
                <>
                  <FieldArray name="investment">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.investment.length > 0 &&
                          values.investment.map((investment, index) => (
                            <div className="row box" key={index}>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label" htmlFor={`investment.${index}.financialInstitution`}>
                                  Financial Institution
                                </label>
                                <input
                                  id={`investment.${index}.financialInstitution`}
                                  name={`investment.${index}.financialInstitution`}
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.investment[index].financialInstitution}
                                />
                                <div className="text-danger"><ErrorMessage name={`investment.${index}.financialInstitution`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label" htmlFor={`investment.${index}.accountNumber`}>
                                  Account Number(last four digits)
                                </label>
                                <input
                                  id={`investment.${index}.accountNumber`}
                                  name={`investment.${index}.accountNumber`}
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.investment[index].accountNumber}
                                />
                                <div className="text-danger"><ErrorMessage name={`investment.${index}.accountNumber`} /></div>
                              </div>
                              <div className="asset-label col-lg-8 d-flex justify-content-between align-items-center mb-0">
                                <label
                                  className=" form-check-label asset-label-checkbox"
                                  htmlFor={`flexCheckChecked`}
                                >
                                  Is this a joint account of you and your spouse?
                                </label>
                                <div className="d-flex">
                                  <div className="me-5">
                                    <label htmlFor={`investment.${index}.isJoint.yes`}>Yes</label>
                                    <input
                                      id={`investment.${index}.isJoint.yes`}
                                      name={`investment.${index}.isJoint`}
                                      type="radio"
                                      className="form-check-input ms-1"
                                      value="true"
                                      onChange={handleChange}
                                      checked={values.investment[index].isJoint === "true"}
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor={`investment.${index}.isJoint.no`}>No</label>
                                    <input
                                      id={`investment.${index}.isJoint.no`}
                                      name={`investment.${index}.isJoint`}
                                      type="radio"
                                      className="form-check-input ms-1"
                                      value="false"
                                      onChange={handleChange}
                                      checked={values.investment[index].isJoint === "false"}
                                    />
                                  </div>
                                </div>
                                <div className="text-danger"><ErrorMessage name={`investment.${index}.isJoint`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Date opened</label>
                                <input
                                  id={`investment.${index}.date`}
                                  name={`investment.${index}.date`}
                                  type="Date"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.investment[index].date}
                                />
                                <div className="text-danger"><ErrorMessage name={`investment.${index}.date`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Balance as of date of marriage commencement</label>
                                <input
                                  id={`investment.${index}.marriageCommencementBalance`}
                                  name={`investment.${index}.marriageCommencementBalance`}
                                  type="number"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.investment[index].marriageCommencementBalance}
                                />
                                <div className="text-danger"><ErrorMessage name={`investment.${index}.marriageCommencementBalance`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Current balance or balance as of divorce filing date</label>
                                <input
                                  id={`investment.${index}.currentBalance`}
                                  name={`investment.${index}.currentBalance`}
                                  type="number"
                                  className="form-control"
                                  onChange={(data) => { handleChange(data); onChangeCalculateTotal(); }}
                                  value={values.investment[index].currentBalance}
                                />
                                <div className="text-danger"><ErrorMessage name={`investment.${index}.currentBalance`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Source of Funds</label>
                                <select
                                  id={`investment.${index}.sourceOfFunds`}
                                  name={`investment.${index}.sourceOfFunds`}
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.investment[index].sourceOfFunds}
                                >
                                  <option value="">Select Source</option>
                                  <option value="Separate Income/Property">Separate Income/Property</option>
                                  <option value="Marital Income/Property">Marital Income/Property</option>
                                  <option value="Other">Other</option>
                                </select>
                                <div className="text-danger"><ErrorMessage className="error" name={`investment.${index}.sourceOfFunds`} /></div>
                              </div>

                              {values.investment.length >= 1 && (
                                <div className="col-sm-12 mb-3 text-center">
                                  <button type="button" className="btn btn-size btn-secondary mb-2" onClick={() => {remove(index);onRemove()}}>
                                    Remove
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                        <div className="row">
                          <div className="col-sm-12 my-3 text-center">
                            <button
                              className="btn btn-size btn-primary"
                              type="button"
                              onClick={() =>
                                push({
                                  financialInstitution: "",
                                  accountNumber: "",
                                  isJoint: "false",
                                  date: "",
                                  marriageCommencementBalance: "",
                                  currentBalance: 0,
                                  sourceOfFunds: "",
                                })
                              }
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {
                    values.investment.length > 0 &&
                    <div className="row d-flex mt-26 justify-content-end">
                      <div className="d-flex col-lg-4 align-items-center justify-content-end ">
                        <div className="label asset-label">
                          <label className="form-label pb-3 px-3">Total</label>
                        </div>
                        <div className="input">
                          <input className="mb-1-total form-control" value={investmentTotal} readOnly />
                        </div>
                      </div>
                    </div>
                  }
                </>
              )}

              <div className="mt-30 bar" onClick={toggleRetirementVisibility}>
                <div className="container-fluid">
                  <h5 className="p-2">Retirement</h5>
                </div>
              </div>
              {isRetirementVisible && (<>
                <FieldArray name="retirement">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.retirement.length > 0 &&
                        values.retirement.map((retirement, index) => (
                          <div className="row box" key={index}>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label" htmlFor={`retirement.${index}.financialInstitution`}>
                                Financial Institution
                              </label>
                              <input
                                id={`retirement.${index}.financialInstitution`}
                                name={`retirement.${index}.financialInstitution`}
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                value={values.retirement[index].financialInstitution}
                              />
                              <div className="text-danger"><ErrorMessage name={`retirement.${index}.financialInstitution`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label" htmlFor={`retirement.${index}.accountNumber`}>
                                Account Number(last four digits)
                              </label>
                              <input
                                id={`retirement.${index}.accountNumber`}
                                name={`retirement.${index}.accountNumber`}
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                value={values.retirement[index].accountNumber}
                              />
                              <div className="text-danger"><ErrorMessage name={`retirement.${index}.accountNumber`} /></div>
                            </div>
                            <div className="asset-label col-lg-8 d-flex justify-content-between align-items-center mb-0">
                              <label
                                className=" form-check-label asset-label-checkbox"
                                htmlFor={`flexCheckChecked`}
                              >
                                Is this a joint account of you and your spouse?
                              </label>
                              <div className="d-flex">
                                <div className="me-5">
                                  <label htmlFor={`retirement.${index}.isJoint.yes`}>Yes</label>
                                  <input
                                    id={`retirement.${index}.isJoint.yes`}
                                    name={`retirement.${index}.isJoint`}
                                    type="radio"
                                    className="form-check-input ms-1"
                                    value="true"
                                    onChange={handleChange}
                                    checked={values.retirement[index].isJoint === "true"}
                                  />
                                </div>
                                <div>
                                  <label htmlFor={`retirement.${index}.isJoint.no`}>No</label>
                                  <input
                                    id={`retirement.${index}.isJoint.no`}
                                    name={`retirement.${index}.isJoint`}
                                    type="radio"
                                    className="form-check-input ms-1"
                                    value="false"
                                    onChange={handleChange}
                                    checked={values.retirement[index].isJoint === "false"}
                                  />
                                </div>
                              </div>
                              <div className="text-danger"><ErrorMessage name={`retirement.${index}.isJoint`}>
                                {errorMessage => (
                                  <div className="error-message">{errorMessage}</div>
                                )}
                              </ErrorMessage></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Date opened</label>
                              <input
                                id={`retirement.${index}.date`}
                                name={`retirement.${index}.date`}
                                type="Date"
                                className="form-control"
                                onChange={handleChange}
                                value={values.retirement[index].date}
                              />
                              <div className="text-danger"><ErrorMessage name={`retirement.${index}.date`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Balance as of date of marriage commencement</label>
                              <input
                                id={`retirement.${index}.marriageCommencementBalance`}
                                name={`retirement.${index}.marriageCommencementBalance`}
                                type="number"
                                className="form-control"
                                onChange={handleChange}
                                value={values.retirement[index].marriageCommencementBalance}
                              />
                              <div className="text-danger"><ErrorMessage name={`retirement.${index}.marriageCommencementBalance`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Current balance or balance as of divorce filing date</label>
                              <input
                                id={`retirement.${index}.currentBalance`}
                                name={`retirement.${index}.currentBalance`}
                                type="number"
                                className="form-control"
                                onChange={(data) => { handleChange(data); onChangeCalculateTotal(); }}
                                value={values.retirement[index].currentBalance}
                              />
                              <div className="text-danger"><ErrorMessage name={`retirement.${index}.currentBalance`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Source of Funds</label>
                              <select
                                id={`retirement.${index}.sourceOfFunds`}
                                name={`retirement.${index}.sourceOfFunds`}
                                className="form-control"
                                onChange={handleChange}
                                value={values.retirement[index].sourceOfFunds}
                              >
                                <option value="">Select Source</option>
                                <option value="Separate Income/Property">Separate Income/Property</option>
                                <option value="Marital Income/Property">Marital Income/Property</option>
                                <option value="Other">Other</option>
                              </select>
                              <div className="text-danger"><ErrorMessage className="error" name={`retirement.${index}.sourceOfFunds`} /></div>
                            </div>

                            {values.retirement.length >= 1 && (
                              <div className="col-sm-12 mb-3 text-center">
                                <button type="button" className="btn btn-size btn-secondary mb-2" onClick={() => {remove(index);onRemove()}}>
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      <div className="row">
                        <div className="col-sm-12 my-3 text-center">
                          <button
                            className="btn btn-size btn-primary"
                            type="button"
                            onClick={() =>
                              push({
                                financialInstitution: "",
                                accountNumber: "",
                                isJoint: "false",
                                date: "",
                                marriageCommencementBalance: "",
                                currentBalance: 0,
                                sourceOfFunds: "",
                              })
                            }
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                {
                  values.retirement.length > 0 &&
                  <div className="row d-flex mt-26 justify-content-end">
                    <div className="d-flex col-lg-4 align-items-center justify-content-end ">
                      <div className="label asset-label">
                        <label className="form-label pb-3 px-3">Total</label>
                      </div>
                      <div className="input">
                        <input className="mb-1-total form-control" value={retirementTotal} readOnly />
                      </div>
                    </div>
                  </div>
                }
              </>
              )}

              <div className="row my-4">
                <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-end order-lg-last order-md-last order-sm-first">
                  <button type="submit" className="btn btn-size btn-secondary mb-sm-10 me-2">Save</button>
                  <button type="button" className="btn btn-size btn-primary" onClick={handleContinue}>Continue</button>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mb-3 mb-sm-0">
                  <Link to="/monthly-expense" className="nav-link"><i className="fa fa-chevron-left mt-3 me-2"></i> Back</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default Assets;