import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class PlansService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/plans";
  }

  getPlans() {
    return this.get(this.apiUrl+ '/get');
  }

  verifyCoupon(coupon) {
    return this.get(this.apiUrl+ '/verify-coupon/'+coupon);
  }

  getCheckoutPage(data) {
    return this.post(this.apiUrl+ '/checkout/', data);
  }

  purchasePlan(data) {
    return this.post(this.apiUrl+ '/purchase-plan/', data);
  }
}

