export const ONBOARD_TYPES = {
  DIVORCE: 'divorce',
  COHABILITATION: 'cohabilitation',
  PRENUP: 'prenup',
  OTHER: 'other'
};

// Divorce Step One and Step Two ENUM
export const YOU_STAND_ENUM = {
  NOT_WANT_DIVORCE: "not-want-divorce",
  THINKING: "thinking",
  READY: "ready",
};

export const CURRENT_RELATIONSHIP_STATE = {
  GOOD_TERM: "good-term",
  SPEAKING_WELL: "speaking-well",
  SOME_AVERAGE: "some-average",
  TOUGH_CONFLICT: "tough-conflict",
};

export const ESTIMATED_NET_WORTH = {
  LESS_THAN_200K: "less-than-200k",
  BETWEEN_200K_AND_500K: "between-200k-and-500k",
  MORE_THAN_500K: "more-than-500k",
};

export const BIGGEST_CHALLENGE_ENUM = {
  SETTLEMENT: "settlement",
  AFFORD_ATTORNEY: "afford-attorney",
  PROTECT_CHILD_INTREST: "protect-child-intrest",
  PARALYZING_PROCESS: "paralyzing-process",
};

export const HIRED_PROFESSIONAL = {
  DIVORCE_ATTORNEY: "divorce-attorney",
  MEDIATOR: "mediator",
  CDFA: "cdfa", // Certified divorced financial analyst
  DIVORCE_COACH: "divorce-coach",
  NOT_HIRED_ANY: "not-hired-any",
};

export const HIRED_PROFESSIONAL_FEE = {
  LESS_THAN_5K: "less-than-5k",
  BETWEEN_5K_AND_10K: "between-5k-and-10k",
  BETWEEN_10K_AND_20K: "between-10k-and-20k",
  MORE_THAN_20K: "more-than-20k",
};

export const FINANCIAL_SETTLEMENT = {
  PROTECTING_ASSETS: "protecting-assets",
  FAIR_DIVISION: "fair-division",
  MARTIAL_HOME: "martial-home",
  CHILD_SPOUSE_SUPPORT: "child-spouse-support",
};

// Prenup Steps ENUM
export const RELATIONSHIP_DURATION = {
  LESS_THAN_ONE_YEAR: "less-than-one-year",
  ONE_THREE_YEAR: "one-to-three-year",
  FIVE_SEVEN_YEAR: "five-to-seven-year",
  MORE_THAN_SEVEN_YEAR: "more-than-seven-year",
};

export const PRENUP_PRIORITIES = {
  PROTECTING_ASSETS: "protecting-assets",
  PROTECTING_INHERITANCE: "protecting-inheritance",
  HANDLING_DEBTS: "handling-debts",
  SHARING_FINANCES: "sharing-finances",
  OTHER: "other",
};

export const PRENUP_WHAT_APPLIES = {
  PAYING_CHILD_SUPPORT: "paying-child-support",
  RECEIVING_CHILD_SUPPORT: "receiving-child-support",
};

export const INDIVIDUAL_INCOME_RANGE = {
  LESS_THAN_70K: "less-than-70k",
  BETWEEN_70K_AND_150K: "between-70k-and-150k",
  MORE_THAN_150K: "more-than-150k",
};

export const TOTAL_NET_WORTH = {
  LESS_THAN_200K: "less-than-200k",
  MORE_THAN_200K: "more-than-200k",
};

// Cohabitation ENUM
export const WHY_COHABITATION_AGREEMENT = {
  DIPLAY_IN_INCOME_WEALTH: "display-income-wealth",
  WORRIED_DEBT_LOAN: "worried-debt-loan",
  SEE_RULES_FOR_SHARING_FINANCES: "see-rules-for-sharing-finances",
  OHTER_PLANING: "other-planing",
};

// Other Steps ENUM
export const YOUR_INTREST = {
  SUPPORT_MODIFICATIONS: "support-modifications",
  COHABITATION_AGREEMENT: "cohabilitation-agreement",
  ADOPTION: "adoption",
  ESTATE_PLANING: "estate-planing",
  OTHER_SIGNUP_NEWSLETTER: "signup-newsletter",
};
