import { size } from "lodash";
import moment from "moment";

export const isPlanExpired = (data) => {
  const { plan } = data;
  const currentDate = moment();
  const endDate1 = moment(plan[0]?.subscriptionEndDate);
  if(size(plan) > 1){
    const endDate2 = moment(plan[1]?.subscriptionEndDate);
    if(endDate1.isAfter(currentDate) || endDate2.isAfter(currentDate) ) {
      return false;
    } else return true;
  } else if (size(plan) > 0) {
    if(endDate1.isAfter(currentDate)) {
      return false;
    } else return true;
  }
};

export const isTrialPlan = (data) => {
    const { trialPlanAvailed, trialPlanEndDate } = data;
  
    const currentDate = moment();
    const trialEndDate = moment(trialPlanEndDate);
    
    if(trialPlanAvailed && trialEndDate.isAfter(currentDate)) {
      return true;
    } else return false;  
}
