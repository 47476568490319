import React, { useEffect, useState } from "react";
import "./index.css";
import { ToastContainer } from "react-toastify";
import PlanCard from "./PlanCard";
import { Row } from "react-bootstrap";
import { BasicFeatures, AdvancedFeatures, TermsAndConditions, PLAN_TYPES } from "../../../constants/plan.constants";
import { PlansService } from "../../../services/plans.service";
import { isEmpty } from "lodash";
import { useStripe } from "@stripe/react-stripe-js";
import { useUser } from "@clerk/clerk-react";

const { BASIC, ADVANCED } = PLAN_TYPES;

const Plans = () => {
  const { user } = useUser();
  const stripe = useStripe();
  const plansService = new PlansService();

  const [plansData, setPlansData] = useState([]);

  const getAllPlans = async () => {
    try {
      const { data } = await plansService.getPlans();
      if (!isEmpty(data?.data)) {
        setPlansData(data.data);
        localStorage.setItem("plans", JSON.stringify(data.data));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const startNow = () => {
    const plan = plansData.find(el => el.type === BASIC);
    if(!isEmpty(plan)) {
      handleCheckout(plan);
    }
  }

  const getItAll = () => {
    // const plan = plansData.find(el => el.type === ADVANCED);
    // handleCheckout(plan);
    window.location.href = "https://buy.stripe.com/fZe16j3So1HG6xa8wA";
  }

  const handleCheckout = (plan) => {
    if(!isEmpty(plan)) {
      const data = { planId: plan?._id, userId: user.id }
      plansService.getCheckoutPage(data).then((res) => {
        stripe.redirectToCheckout({ sessionId: res?.data.data?.id });
      }).catch((err) => {
        console.log('err', err);
      })
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="container col-md-12">
        <img
          className="my-5"
          style={{ paddingLeft: "10px" }}
          src="assets/img/logo.svg"
        />
        <div className="my-2">
          <div className="d-flex align-items-center mb-2">
            <div role="group" className="col-md-12">
              <div className="pb-5">
                
              </div>
              <div className="d-flex justify-content-between mx-4">
                <Row>
                  <PlanCard
                    title="Basic"
                    heading="Free Trial"
                    content1="Get your financials organized."
                    content2=" Free for 7 days, then $116/year."
                    features={BasicFeatures}
                    buttonText="Start now"
                    clickHandler={startNow}
                  />
                </Row>
                <Row>
                  <PlanCard
                    title="Advanced"
                    heading="$392"
                    content1="For less than a coffee a day,"
                    content2="learn to uncover hidden assets & secure your financial future"
                    features={AdvancedFeatures}
                    buttonText="Buy Now"
                    clickHandler={getItAll}
                  />
                </Row>
              </div>
              <div className="mx-5 pt-5 pb-2">
                <div className="mx-5">
                  <p>Policies, Terms of Use & Disclaimer:</p>
                  <ul>
                    {TermsAndConditions.map((terms, index) => (
                      <li className="pb-4" key={index}>
                        {terms}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
