import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { YOUR_INTREST } from "../constants";
import { ROUTES } from "../../../routes/routes";
import { isEmpty } from "lodash";
import { OnboardService } from "../../../services/onboardService";
import Error from "../common/Error";

const { CONFIRMATION, ONBOARD } = ROUTES;
const { SUPPORT_MODIFICATIONS, COHABITATION_AGREEMENT, ESTATE_PLANING, OTHER_SIGNUP_NEWSLETTER, ADOPTION, } = YOUR_INTREST;

const validationSchema = Yup.object().shape({
  state: Yup.string().optional(),
  yourIntrest: Yup.date().required("Required field."),
});
const initialValues = {
  state: "",
  yourIntrest: ""
};

const OtherStepOne = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const onboardService = new OnboardService();
  const [error, setError] = useState(initialValues);

  const formik = useFormik({ initialValues, validationSchema });
  const { values, setFieldValue, setValues } = formik;

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("onboardDetails"));
    if (
      !isEmpty(savedData) &&
      savedData?.currentStep == "step-1" &&
      savedData?.type == "other"
    ) {
      setValues({
        state: savedData.state,
        yourIntrest:savedData?.yourIntrest,
      });
    }
  }, []);

  const handleContinue = async () => {
    try {
      let isValidationError = false;
      if (isEmpty(values.state)) {
        setError({ ...error, state: "Required value" });
        isValidationError = true;
        return;

      }
      if (isEmpty(values.yourIntrest)) {
        setError({ ...error, yourIntrest: "Required value" });
        isValidationError = true;
        return;
      }
      // if(isValidationError) {
      //   return;
      // }

      const payload = { userId: user?.id, currentStep: "step-1", ...values };
      const { data } = await onboardService.update(payload);
      if (!isEmpty(data?.data)) {
        localStorage.setItem('onboardDetails', JSON.stringify(data.data));
        navigate(CONFIRMATION);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleBack = () => {
    navigate(ONBOARD);
  }

  return (
    <>
      <ToastContainer />
      <img className="m-4" src="assets/img/logo.svg" />
      <div className="avenue-other-step1"></div>
      <div className="container py-5 col-md-12">
        <Formik>
          <Form>
            <div className="row my-2">
              <div className="row d-flex align-items-center mb-2">
                <div role="group" className="col-md-12">
                <div className="col-lg-3 col-md-3 mb-3">
                    <label className="asset-label">
                      Which state you are in
                    </label>
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      onChange={(e) => {
                        setError({ ...error, state: "" });
                        setFieldValue("state", e.target.value);
                      }}
                      value={values.state}
                    />
                    {error.state && <Error />}
                  </div>
                  <div className="mt-5 asset-label">Tell us what you are intrested in</div>
                  <div
                    onClick={() => {
                      setError({ ...error, yourIntrest: "" });
                      setFieldValue("yourIntrest", SUPPORT_MODIFICATIONS)
                    }}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-5 px-3 py-1 border-rounded-5 form-check ${
                      values.yourIntrest === SUPPORT_MODIFICATIONS
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.yourIntrest === SUPPORT_MODIFICATIONS
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.yourIntrest === SUPPORT_MODIFICATIONS ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.yourIntrest === SUPPORT_MODIFICATIONS
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Alimony and/or child support modifications
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setError({ ...error, yourIntrest: "" });
                      setFieldValue("yourIntrest", COHABITATION_AGREEMENT)
                    }}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-5 px-3 py-1 form-check ${
                      values.yourIntrest === COHABITATION_AGREEMENT
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.yourIntrest === COHABITATION_AGREEMENT
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight: values.yourIntrest === COHABITATION_AGREEMENT ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.yourIntrest === COHABITATION_AGREEMENT
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Cohabitation/coparenting agreement
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setError({ ...error, yourIntrest: "" });
                      setFieldValue("yourIntrest", ADOPTION)
                    }}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-5 px-3 py-1 border-rounded-5 form-check ${
                      values.yourIntrest === ADOPTION
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.yourIntrest === ADOPTION
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.yourIntrest === ADOPTION ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.yourIntrest === ADOPTION
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Adoption/surrogacy
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setError({ ...error, yourIntrest: "" });
                      setFieldValue("yourIntrest", ESTATE_PLANING)
                    }}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-5 px-3 py-1 border-rounded-5 form-check ${
                      values.yourIntrest === ESTATE_PLANING
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.yourIntrest === ESTATE_PLANING
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.yourIntrest === ESTATE_PLANING ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.yourIntrest === ESTATE_PLANING
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Estate planing (trust and will)
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setError({ ...error, yourIntrest: "" });
                      setFieldValue("yourIntrest", OTHER_SIGNUP_NEWSLETTER)
                    }}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-5 px-3 py-1 border-rounded-5 form-check ${
                      values.yourIntrest === OTHER_SIGNUP_NEWSLETTER
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.yourIntrest === OTHER_SIGNUP_NEWSLETTER
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.yourIntrest === OTHER_SIGNUP_NEWSLETTER ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.yourIntrest === OTHER_SIGNUP_NEWSLETTER
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Other: sign me up to your newsletter
                    </label>
                  </div>
                  {error.yourIntrest && <Error />}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-between order-lg-last order-md-last order-sm-first">
                <button
                  type="submit"
                  className="btn btn-size btn-secondary mb-sm-10 me-2"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-size btn-primary"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default OtherStepOne;
