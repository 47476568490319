import { useUser } from "@clerk/clerk-react";
import { React, useState, useRef, useEffect } from "react";
import { Formik, FieldArray, Form, ErrorMessage } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { LiabilitiesService } from "../../services/liabilities.service";

const validationSchema = Yup.object().shape({
  creditCard: Yup.array().of(
    Yup.object().shape({
      purpose: Yup.string().required("Required field."),
      financialInstitution: Yup.string().required("Required field."),
      isJoint: Yup.bool().required("Required field."),
      marriageCommencementBalance: Yup.string().required("Required field."),
      currentBalance: Yup.number().required("Required field."),
    })
  ),
  studentLoan: Yup.array().of(
    Yup.object().shape({
      purpose: Yup.string().required("Required field."),
      financialInstitution: Yup.string().required("Required field."),
      isJoint: Yup.bool().required("Required field."),
      marriageCommencementBalance: Yup.string().required("Required field."),
      currentBalance: Yup.number().required("Required field."),
    })
  ),
  personalLoan: Yup.array().of(
    Yup.object().shape({
      purpose: Yup.string().required("Required field."),
      financialInstitution: Yup.string().required("Required field."),
      isJoint: Yup.bool().required("Required field."),
      marriageCommencementBalance: Yup.string().required("Required field."),
      currentBalance: Yup.number().required("Required field."),
    })
  ),
});

const initialValues = {
  creditCard: [
    {
      purpose: "",
      financialInstitution: "",
      isJoint: "false",
      marriageCommencementBalance: "",
      currentBalance: 0,
    }
  ],
  studentLoan: [],
  personalLoan: [],
};

const Liabilities = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const formRef = useRef();
  const liabilitiesService = new LiabilitiesService();
  const [creditCardTotal, setCreditCardTotal] = useState(0);
  const [studentLoanTotal, setStudentLoanTotal] = useState(0);
  const [personalLoanTotal, setPersonalLoanTotal] = useState(0);

  const [isStudentLoanVisible, setIsStudentLoanVisible] = useState(false);
  const [isPersonalLoanVisible, setIsPersonalLoanVisible] = useState(false);
  const [recordId, setRecordId] = useState("");

  const toggleStudentLoanVisibility = () => {
    setIsStudentLoanVisible(!isStudentLoanVisible);
  };

  const togglePersonalLoanVisibility = () => {
    setIsPersonalLoanVisible(!isPersonalLoanVisible);
  };

  const handleContinue = () => {
    formRef.current.handleSubmit();
    if (Object.keys(formRef.current.errors).length === 0) {
      navigate("/settlement");
    }
  };

  const onChangeCalculateTotal = () => {
    setTimeout(() => {
      const values = formRef.current.values;
      let creditCardBalanceTotal = 0;
      let studentLoanBalanceTotal = 0;
      let personalLoanBalanceTotal = 0;

      values.creditCard.forEach((creditCard) => {
        creditCardBalanceTotal += parseFloat(creditCard.currentBalance) || 0;
      });
      setCreditCardTotal(creditCardBalanceTotal);

      values.personalLoan.forEach((personalLoan) => {
        personalLoanBalanceTotal += parseFloat(personalLoan.currentBalance) || 0;
      });
      setPersonalLoanTotal(personalLoanBalanceTotal);

      values.studentLoan.forEach((studentLoan) => {
        studentLoanBalanceTotal += parseFloat(studentLoan.currentBalance) || 0;
      });
      setStudentLoanTotal(studentLoanBalanceTotal);
    }, 500);
  }

  const getData = () => {
    liabilitiesService.getData({ "userId": user.id })
      .then((response) => {
        if (response.data?.length) {
          formRef.current.setValues(response.data[0]);
          setRecordId(response.data[0]._id);

          if (response.data[0]?.personalLoan?.length) {
            setIsPersonalLoanVisible(true);
          }

          if (response.data[0]?.studentLoan?.length) {
            setIsStudentLoanVisible(true);
          }
        }

        onChangeCalculateTotal();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container border expenses-bg">
        <div className="d-flex justify-content-center">
          <div className="text-center pt-40">
            <i className="fas-bg fas fa-home fa-2x"></i>
            <h4 className="heading-4 mt-13">Instructions </h4>
            <p className="justify-content-start expenses-Paragraph mt-13">
              This section includes all of your debts including credit cards, mortgages, or loans you must repay (even loans from family). It’s important to have accurate date of incurred debt and the purpose of the debts, as that will determine which are separate debts and which are community debts. You may skip your home mortgage at the moment in this section.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (recordId) {
              liabilitiesService.update(recordId, values)
              toast.success("Assets data Updated");
              return
            }
            values.userId = user.id;
            toast.success("Liabilities data saved");
            liabilitiesService.create(values)
          }}>
          {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
            <Form noValidate autoComplete="off">
              <div>
                <div className="mt-29 bar">
                  <h5 className="p-2">Credit Card Debit</h5>
                </div>

                <FieldArray name="creditCard">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.creditCard.length > 0 &&
                        values.creditCard.map((creditCard, index) => (
                          <div className="row box" key={index}>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label htmlFor={`creditCard.${index}.purpose`} className="asset-label ">
                                Purpose
                              </label>
                              <select
                                id={`creditCard.${index}.purpose`}
                                name={`creditCard.${index}.purpose`}
                                className="form-control"
                                onChange={handleChange}
                                value={values.creditCard[index].purpose}
                                onBlur={handleBlur}
                              >
                                <option value="">Select Purpose</option>
                                <option value="myself">For myself</option>
                                <option value="spouse">For my spouse</option>
                                <option value="both">For both of us</option>
                              </select>
                              <div className="text-danger"><ErrorMessage name={`creditCard.${index}.purpose`} /></div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label" htmlFor={`creditCard.${index}.financialInstitution`}>
                                Financial Institution
                              </label>
                              <input
                                id={`creditCard.${index}.financialInstitution`}
                                name={`creditCard.${index}.financialInstitution`}
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                value={values.creditCard[index].financialInstitution}
                              />
                              <div className="text-danger"><ErrorMessage name={`creditCard.${index}.financialInstitution`} /></div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Balance as of date of marriage commencement</label>
                              <input
                                id={`creditCard.${index}.marriageCommencementBalance`}
                                name={`creditCard.${index}.marriageCommencementBalance`}
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                value={values.creditCard[index].marriageCommencementBalance}
                              />
                              <div className="text-danger"><ErrorMessage name={`creditCard.${index}.marriageCommencementBalance`} /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                              <label className="asset-label">Current balance or balance as of divorce filing date</label>
                              <input
                                id={`creditCard.${index}.currentBalance`}
                                name={`creditCard.${index}.currentBalance`}
                                type="number"
                                className="form-control"
                                onChange={(data) => { handleChange(data); onChangeCalculateTotal(); }}
                                value={values.creditCard[index].currentBalance}
                              />
                              <div className="text-danger"><ErrorMessage name={`creditCard.${index}.currentBalance`} /></div>
                            </div>

                            <div className="asset-label col-lg-8 d-flex justify-content-between align-items-center mb-0">
                              <label
                                className=" form-check-label asset-label-checkbox"
                                htmlFor={`flexCheckChecked`}
                              >
                                Is this a joint account of you and your spouse?
                              </label>
                              <div className="d-flex">
                                <div className="me-5">
                                  <label htmlFor={`creditCard.${index}.isJoint.true`}>Yes</label>
                                  <input
                                    id={`creditCard.${index}.isJoint.true`}
                                    name={`creditCard.${index}.isJoint`}
                                    type="radio"
                                    className="form-check-input ms-1"
                                    value="true"
                                    onChange={handleChange}
                                    checked={values.creditCard[index].isJoint === "true"}
                                  />
                                </div>
                                <div>
                                  <label htmlFor={`creditCard.${index}.isJoint.false`}>No</label>
                                  <input
                                    id={`creditCard.${index}.isJoint.false`}
                                    name={`creditCard.${index}.isJoint`}
                                    type="radio"
                                    className="form-check-input ms-1"
                                    value="false"
                                    onChange={handleChange}
                                    checked={values.creditCard[index].isJoint === "false"}
                                  />
                                </div>
                              </div>
                              <div className="text-danger"><ErrorMessage name={`creditCard.${index}.isJoint`} /></div>
                            </div>

                            <div className="col-12 d-flex justify-content-center mt-5">
                              {index !== values.creditCard.length - 1 && (
                                <button type="button" className="btn btn-size btn-secondary mb-2" onClick={() => { remove(index); onChangeCalculateTotal() }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      <div className="row d-flex justify-content-center mt-5">
                        <button
                          className="btn btn-size btn-primary"
                          type="button"
                          onClick={() =>
                            push({
                              purpose: "",
                              financialInstitution: "",
                              isJoint: "false",
                              marriageCommencementBalance: "",
                              currentBalance: 0,
                            })
                          }
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <div className="row d-flex mt-26 justify-content-end">
                  <div className="d-flex col-lg-4 align-items-center justify-content-end ">
                    <div className="label asset-label">
                      <label className="form-label pb-3 px-3">Total</label>
                    </div>
                    <div className="input">
                      <input className="mb-1-total form-control" value={creditCardTotal} readOnly />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-30 bar" onClick={toggleStudentLoanVisibility}>
                <div className="container-fluid">
                  <h5 className="p-2">Student Loan</h5>
                </div>
              </div>
              {isStudentLoanVisible && (
                <>
                  <FieldArray name="studentLoan">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.studentLoan.length > 0 &&
                          values.studentLoan.map((studentLoan, index) => (
                            <div className="row box" key={index}>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label htmlFor={`studentLoan.${index}.purpose`} className="asset-label ">
                                  Purpose
                                </label>
                                <select
                                  id={`studentLoan.${index}.purpose`}
                                  name={`studentLoan.${index}.purpose`}
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.studentLoan[index].purpose}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select Purpose</option>
                                  <option value="myself">For myself</option>
                                  <option value="spouse">For my spouse</option>
                                  <option value="both">For both of us</option>
                                </select>
                                <div className="text-danger"><ErrorMessage name={`studentLoan.${index}.purpose`} /></div>
                              </div>

                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label" htmlFor={`studentLoan.${index}.financialInstitution`}>
                                  Financial Institution
                                </label>
                                <input
                                  id={`studentLoan.${index}.financialInstitution`}
                                  name={`studentLoan.${index}.financialInstitution`}
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.studentLoan[index].financialInstitution}
                                />
                                <div className="text-danger"><ErrorMessage name={`studentLoan.${index}.financialInstitution`} /></div>
                              </div>

                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Balance as of date of marriage commencement</label>
                                <input
                                  id={`studentLoan.${index}.marriageCommencementBalance`}
                                  name={`studentLoan.${index}.marriageCommencementBalance`}
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.studentLoan[index].marriageCommencementBalance}
                                />
                                <div className="text-danger"><ErrorMessage name={`studentLoan.${index}.marriageCommencementBalance`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Current balance or balance as of divorce filing date</label>
                                <input
                                  id={`studentLoan.${index}.currentBalance`}
                                  name={`studentLoan.${index}.currentBalance`}
                                  type="number"
                                  className="form-control"
                                  onChange={(data) => { handleChange(data); onChangeCalculateTotal(); }}
                                  value={values.studentLoan[index].currentBalance}
                                />
                                <div className="text-danger"><ErrorMessage name={`studentLoan.${index}.currentBalance`} /></div>
                              </div>

                              <div className="asset-label col-lg-8 d-flex justify-content-between align-items-center mb-0">
                                <label
                                  className=" form-check-label asset-label-checkbox"
                                  htmlFor={`flexCheckChecked`}
                                >
                                  Is this a joint account of you and your spouse?
                                </label>
                                <div className="d-flex">
                                  <div className="me-5">
                                    <label htmlFor={`studentLoan.${index}.isJoint.true`}>Yes</label>
                                    <input
                                      id={`studentLoan.${index}.isJoint.true`}
                                      name={`studentLoan.${index}.isJoint`}
                                      type="radio"
                                      className="form-check-input ms-1"
                                      value="true"
                                      onChange={handleChange}
                                      checked={values.studentLoan[index].isJoint === "true"}
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor={`studentLoan.${index}.isJoint.false`}>No</label>
                                    <input
                                      id={`studentLoan.${index}.isJoint.false`}
                                      name={`studentLoan.${index}.isJoint`}
                                      type="radio"
                                      className="form-check-input ms-1"
                                      value="false"
                                      onChange={handleChange}
                                      checked={values.studentLoan[index].isJoint === "false"}
                                    />
                                  </div>
                                </div>
                                <div className="text-danger"><ErrorMessage name={`studentLoan.${index}.isJoint`} /></div>
                              </div>

                              <div className="col-12 d-flex justify-content-center mt-5">
                                {values.creditCard.length >= 1 && (
                                  <button type="button" className="btn btn-size btn-secondary mb-2" onClick={() => { remove(index); onChangeCalculateTotal() }}>
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="row">
                          <div className="col-sm-12 my-3 text-center">
                            <button
                              className="btn btn-size btn-primary"
                              type="button"
                              onClick={() =>
                                push({
                                  purpose: "",
                                  financialInstitution: "",
                                  isJoint: "false",
                                  marriageCommencementBalance: "",
                                  currentBalance: 0,
                                })
                              }
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  {
                    values.studentLoan.length > 0 && <div className="row d-flex mt-26 justify-content-end">
                      <div className="d-flex col-lg-4 align-items-center justify-content-end ">
                        <div className="label asset-label">
                          <label className="form-label pb-3 px-3">Total</label>
                        </div>
                        <div className="input">
                          <input className="mb-1-total form-control" value={studentLoanTotal} readOnly />
                        </div>
                      </div>
                    </div>
                  }
                </>
              )}

              <div className="mt-30 bar" onClick={togglePersonalLoanVisibility}>
                <div className="container-fluid">
                  <h5 className="p-2">Personal Loan</h5>
                </div>
              </div>
              {isPersonalLoanVisible && (
                <>
                  <FieldArray name="personalLoan">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.personalLoan.length > 0 &&
                          values.personalLoan.map((personalLoan, index) => (
                            <div className="row box" key={index}>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label htmlFor={`personalLoan.${index}.purpose`} className="asset-label ">
                                  Purpose
                                </label>
                                <select
                                  id={`personalLoan.${index}.purpose`}
                                  name={`personalLoan.${index}.purpose`}
                                  className="form-control"
                                  onChange={handleChange}
                                  personalLoan={values.personalLoan[index].purpose}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select Purpose</option>
                                  <option value="myself">For myself</option>
                                  <option value="spouse">For my spouse</option>
                                  <option value="both">For both of us</option>
                                </select>
                                <div className="text-danger"><ErrorMessage name={`personalLoan.${index}.purpose`} /></div>
                              </div>

                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label" htmlFor={`personalLoan.${index}.financialInstitution`}>
                                  Financial Institution
                                </label>
                                <input
                                  id={`personalLoan.${index}.financialInstitution`}
                                  name={`personalLoan.${index}.financialInstitution`}
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.personalLoan[index].financialInstitution}
                                />
                                <div className="text-danger"><ErrorMessage name={`personalLoan.${index}.financialInstitution`} /></div>
                              </div>

                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Balance as of date of marriage commencement</label>
                                <input
                                  id={`personalLoan.${index}.marriageCommencementBalance`}
                                  name={`personalLoan.${index}.marriageCommencementBalance`}
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.personalLoan[index].marriageCommencementBalance}
                                />
                                <div className="text-danger"><ErrorMessage name={`personalLoan.${index}.marriageCommencementBalance`} /></div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 mb-23">
                                <label className="asset-label">Current balance or balance as of divorce filing date</label>
                                <input
                                  id={`personalLoan.${index}.currentBalance`}
                                  name={`personalLoan.${index}.currentBalance`}
                                  type="number"
                                  className="form-control"
                                  onChange={(data) => { handleChange(data); onChangeCalculateTotal(); }}
                                  value={values.personalLoan[index].currentBalance}
                                />
                                <div className="text-danger"><ErrorMessage name={`personalLoan.${index}.currentBalance`} /></div>
                              </div>

                              <div className="asset-label col-lg-8 d-flex justify-content-between align-items-center mb-0">
                                <label
                                  className=" form-check-label asset-label-checkbox"
                                  htmlFor={`flexCheckChecked`}
                                >
                                  Is this a joint account of you and your spouse?
                                </label>
                                <div className="d-flex">
                                  <div className="me-5">
                                    <label htmlFor={`personalLoan.${index}.isJoint.true`}>Yes</label>
                                    <input
                                      id={`personalLoan.${index}.isJoint.true`}
                                      name={`personalLoan.${index}.isJoint`}
                                      type="radio"
                                      className="form-check-input ms-1"
                                      value="true"
                                      onChange={handleChange}
                                      checked={values.personalLoan[index].isJoint === "true"}
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor={`personalLoan.${index}.isJoint.false`}>No</label>
                                    <input
                                      id={`personalLoan.${index}.isJoint.false`}
                                      name={`personalLoan.${index}.isJoint`}
                                      type="radio"
                                      className="form-check-input ms-1"
                                      value="false"
                                      onChange={handleChange}
                                      checked={values.personalLoan[index].isJoint === "false"}
                                    />
                                  </div>
                                </div>
                                <div className="text-danger"><ErrorMessage name={`personalLoan.${index}.isJoint`} /></div>
                              </div>

                              <div className="col-12 d-flex justify-content-center mt-5">
                                {values.creditCard.length >= 1 && (
                                  <button type="button" className="btn btn-size btn-secondary mb-2" onClick={() => { remove(index); onChangeCalculateTotal() }}>
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="row">
                          <div className="col-sm-12 my-3 text-center">
                            <button
                              className="btn btn-size btn-primary"
                              type="button"
                              onClick={() =>
                                push({
                                  financialInstitution: "",
                                  accountNumber: "",
                                  isJoint: "false",
                                  date: "",
                                  marriageCommencementBalance: "",
                                  currentBalance: 0,
                                  sourceOfFunds: "",
                                })
                              }
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  {
                    values.personalLoan.length > 0 && <div className="row d-flex mt-26 justify-content-end">
                      <div className="d-flex col-lg-4 align-items-center justify-content-end ">
                        <div className="label asset-label">
                          <label className="form-label pb-3 px-3">Total</label>
                        </div>
                        <div className="input">
                          <input className="mb-1-total form-control" value={personalLoanTotal} readOnly />
                        </div>
                      </div>
                    </div>
                  }
                </>
              )}

              <div className="row my-4">
                <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-end order-lg-last order-md-last order-sm-first">
                  <button type="submit" className="btn btn-size btn-secondary mb-sm-10 me-2">Save</button>
                  <button type="button" className="btn btn-size btn-primary" onClick={handleContinue}>Confirm</button>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mb-3 mb-sm-0">
                  <Link to="/assets" className="nav-link"><i className="fa fa-chevron-left mt-3 me-2"></i> Back</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default Liabilities;