import React from "react";
import "./index.css";

const Confirmation = () => {
  return (
    <>
      {/* <h1 className="divorce-heading pt-5 avenue-step3">Anew</h1> */}
      <img className="m-4" src="assets/img/logo.svg" />
      <div className="ml-4 avenue-step3"></div>
      <div className="container d-flex py-5 col-md-12">
        {/* <img src="assets/img/other-2.svg" /> */}
        <div className="ml-52 mt-104">
          <h2 className="mt-5 heading-other-1">You are on the list!</h2>
          <p className="mt-5 other-text">
            We will let you know as soon as the product is ready. Stay tuned to
            our newsletter for free workshops and events. See you soon!
          </p>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
