import React from 'react';
import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { IncomeService } from '../services/IncomeService';
import { AssetsService } from '../services/asset.service';
import { LiabilitiesService } from '../services/liabilities.service';
import { ExpenseService } from '../services/expenseService';
import { UserService } from '../services/User.service';
import { OnboardService } from '../services';
import { isEmpty, size } from 'lodash';
import { ROUTES } from '../routes/routes';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [income, setIncome] = useState(false);
  const [asset, setAsset] = useState(false);
  const [liabilities, setLiabilities] = useState(false);
  const [expense, setExpense] = useState(false);
  const [userData, setUserData] = useState({});

  const incomeService = new IncomeService();
  const assetService = new AssetsService();
  const liabilitiesService = new LiabilitiesService();
  const expenseService = new ExpenseService();
  const userService = new UserService();
  const onboardService = new OnboardService();

  const navigate = useNavigate();
  const { user } = useUser();

  const handleRedirect = () => {
    if (asset && income && liabilities && expense) {
      navigate('/settlement');
      return
    }
    toast.error("Please complete the Financial Disclosure section to view");
  };

  const getOnboardingDetails = () => {
    onboardService.getOnboardingDetails(user.id).then((res) => {
      const { data } = res;
      if (isEmpty(data?.data)) {
        navigate(ROUTES.ONBOARD);
      } else {
        localStorage.setItem('onboardDetails', JSON.stringify(data.data));
      }
    });
  };

  const getIncome = () => {
    incomeService.getData({ "userId": user.id }).then((response) => {
      if (response.data?.length) {
        setIncome(true)
      };
    });
  };
  const getAsset = () => {
    assetService.getData({ "userId": user.id })
      .then((response) => {
        if (response.data?.length) {
          setAsset(true)
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const getLiabilities = () => {
    liabilitiesService.getData({ "userId": user.id })
      .then((response) => {
        if (response.data?.length) {
          setLiabilities(true)
        };
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const getExpense = () => {
    expenseService.getData({ "userId": user.id }).then((response) => {
      if (response.data?.length) {
        setExpense(true)
      }
    })
  }

  const updateUser = async () => {
    try {
      await userService.update(userData._id, userData);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const getUser = async () => {
    try {
      const response = await userService.getUser(user.id);
      setUserData(response.data);
      if (!response.data) {
        const data = {
          fName: user.firstName,
          lName: user.lastName,
          id: user.id,
          email: user.emailAddresses[0].emailAddress
        };
        let userDatat = await userService.create(data);
        setUserData(userDatat.data)
      }
      if (!response.data.termAndCondition) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    getOnboardingDetails();
    getIncome();
    getAsset();
    getLiabilities();
    getExpense();
    getUser();
    console.log(user);
  }, []);

  const handleDiscoveryClick = () => {
    if(!isEmpty(userData) && size(userData?.plan)) {
      const isAdvancedPlan = userData?.plan?.find(el => el?.plan?.type == 'Advanced');
      // const isBasicPlan = userData?.plan?.find(el => el?.plan?.type == 'Basic')

      if(isAdvancedPlan) {
        window.open('https://docsend.com/view/j8xgup4vivhskbx5', '_blank');
      } else {
        window.open("https://buy.stripe.com/fZe16j3So1HG6xa8wA");
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="card card-shadow mb-4 mt-3 home">
        <div className="card-body d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div className="content">
            <h4 className="mt-17 heading-4">Financial Disclosure</h4>
            <p className="mb-27 paragraph">Get organized for the legal process and settlement planning</p>
          </div>
          <button className="btn btn-primary" ><Link to="/income" className="nav-link">View</Link></button>
        </div>
      </div>
      <div className="card card-shadow mb-4 home">
        <div className="card-body d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div className="content">
            <h4 className="mt-17 heading-4">Settlement Planning </h4>
            <p className="mb-27 paragraph">Plan for your marital asset & debt distribution and calculate home equity split</p>
          </div>
          <button className="btn btn-primary mt-40" onClick={handleRedirect}>
            View
          </button>
        </div>
      </div>
      <div className="card card-shadow mb-4 home">
        <div className="card-body d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div className="content">
            <h4 className="mt-17 heading-4">Financial Discovery Guide</h4>
            <p className="mb-27 paragraph">Learn how to find hidden accounts, prove asset dissipation and increase your portion of the settlement.</p>
          </div>
          <button onClick={handleDiscoveryClick} className="btn btn-primary">Start</button>
        </div>
      </div>


      <div className={`modal-bg modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden={!isModalOpen}>
        <div className="modal-dialog" role="document">
          <div className="modal-content card-shadow">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Terms & Conditions</h5>
            </div>
            <div className="modal-body p-3">
              <p className='paragraph-term-condition'>
                Anew is a technology platform, not a law firm. The information Anew provides is not legal advice and should not be a substitute for the advice of a licensed professional.  BY SELECTING THE “ACCEPT” BUTTON, YOU ACKNOWLEDGE THAT: (1) YOU ARE 18 YEARS OF AGE OR OLDER; AND (2) YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE TERMS AND CONDITIONS OF THE USER AGREEMENT
              </p>
              <h6 className='text-center'>Anew User Agreement</h6>
              <p className='paragraph-term-condition'>
                This User Agreement (this “Agreement”) constitutes a legal agreement between you (“You,” “Your” or “User”) and Anew Labs Co., a Delaware corporation, with its principal offices located at 8 The Green, STE 4000, Dover, Delaware 19901 (“Anew”) and states the terms and conditions that govern Your use in Anew’s products and/or services. By using or participating in any such products and/or services, You agree to abide by the terms of this Agreement.
              </p>
              <br />
              <ol>
                <li className='modal-text'>Limited Use Rights.  For the Term of this Agreement, Anew hereby grants User a non-exclusive, non-transferable, non-sublicensable, revocable, limited license to use Anew’s technology. Anew hosts and retains control over the Products and only makes them available for access and use over the Internet through a Web-browser.  Anew reserves the right to, in its sole discretion, (i) revoke access and use of the Products any time, (ii) monitor or measure User’s use of the Products, (iii) validate certain features or functionality of the Products, and/or (iv) provide services or support necessary to maintain the Products. User shall not attempt to directly or indirectly, (i) copy, modify, translate, or create derivative works of the Products, (ii) reverse engineer, decompile, disassemble or otherwise attempt to reconstruct, identify or discover any source code, underlying ideas, underlying user interface techniques, or algorithms of any part of the Products, (iii) lend, lease, offer for sale, sell or otherwise use any part of the Products for the benefit of any other third parties, or (iv) attempt to circumvent any license, timing or use restrictions that are built into any part of the Products.</li>
                <br />
                <li className='modal-text'>Intellectual Property. The parties acknowledge that this Agreement does not transfer any right, title or interest in any intellectual property right to the other. Anew maintains all rights, title and interest in and to all its patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights contained in and to (i) the Products, (ii) its online educational platform/programs and (iii) all original works of authorship, inventions, processes, concepts, documents, work product and other materials or other proprietary information made accessible or delivered to User under this Agreement or prepared by or on behalf of Anew in the course of providing the Products (collectively, “Intellectual Property Rights”). The limited rights granted to User to access and use the Products under this Agreement do not convey any additional rights in the Products or in or to any Intellectual Property Rights associated therewith. Subject only to the limited rights to access and use the Products as expressly provided herein, all rights, title and interest in and to the Products and all Intellectual Property Rights will remain with and belong exclusively to Anew.</li>
                <br />
                <li className='modal-text'>Privacy Policy. This Agreement is subject to and incorporates by reference Anew’s <a href='https://www.privacypolicies.com/live/202faaf7-c8a8-4543-8f2e-9221e51a24a3' target='_blank'> Privacy Policy</a>. You acknowledge and agree that by using Anew’s Products, Anew may receive certain information about you, and you hereby consent to Anew’s collection, use and disclosure such information in accordance with the Privacy Policy.</li>
                <br />
                <li className='modal-text'>Compliance with Laws and Representations and Warranties. Each party represents and warrants to the other party that it shall perform its obligations hereunder in compliance with all applicable laws, including export control laws of the United States which are applicable to the provision and use of the Products.</li>
                <br />
                <li className='modal-text'>Disclaimer of Warranties. THE PRODUCTS ARE PROVIDED “AS IS”. ANEW MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE PRODUCTS INCLUDING ANY REPRESENTATION THAT THE SERVICES THEREUNDER WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, ANEW DISCLAIMS ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE IN RESPECT OF THE PRODUCTS. FOR THE AVOIDANCE OF DOUBT, ALL PRODUCTS MAY CONTAIN DEFECTS, ERRORS, INACCURACIES, AND MAY BE SUBSTANTIALLY MODIFIED OR WITHDRAWN AT ANY TIME. ACCORDINGLY, ACCESS TO AND USE OF THE PRODUCTS IS ENTIRELY AT USER’S OWN RISK. IN NO EVENT SHALL ANEW BE LIABLE FOR ANY DAMAGE WHATSOEVER ARISING OUT OF THE USE OF OR INABILITY TO USE THE PRODUCT(S), EVEN IF USER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                <br />
                <li className='modal-text'>Indemnity and Limitation of Liability. User agrees to indemnify and hold Anew, its officers, directors, advisors and employees harmless from any losses (including attorneys’ fees) that result from any third-party claims related to User’s access, use or misuse of the Products or Intellectual Property Rights, or any act or omission by User in violation of this Agreement. To the maximum extent permitted by law, in no event shall Anew be liable to User for any special, indirect, incidental, consequential, punitive or exemplary damages or for loss of profits, revenues, contracts, loss of use, loss of data, business interruption, or cost of replacement goods related to or in connection with this Agreement, even if advised of the possibility of such damages.</li>
                <br />
                <li className='modal-text'>Miscellaneous. If any provision of this Agreement is deemed unenforceable by a tribunal of competent jurisdiction, that provision will be modified to render it enforceable to the extent possible to affect the parties’ intention and the remaining provisions will remain in full force and effect. Failure of Anew to enforce a right under this Agreement shall not act as a waiver of that right or the ability to later assert that right relative to the particular situation involved. This Agreement shall be governed exclusively by the laws of the State of New York, without regard to the State of New York’s conflict of law provisions. The state and federal courts in New York County, New York shall have exclusive jurisdiction and venue over any dispute arising out of or relating to this Agreement, and each party consents to the personal jurisdiction and venue of these courts.</li>
                <br />
              </ol>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={updateUser}>Agree</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
