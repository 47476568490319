import { ROUTES } from "../routes/routes";

const {
  HOME,
  INCOME,
  ASSETS,
  LIABILITIES,
  SETTLEMENT,
  SETTLEMENT_ID,
  CHANGE_PROFILE,
  MONTHLY_EXPENSE,
  SETTLEMENT_CALCULATOR,
  
  TRIAL,

  CHECKOUT,
  ONBOARD,
  DIVORCE_STEP_ONE,
  DIVORCE_STEP_TWO,
  OTHER_STEP_ONE,
  CONFIRMATION,
  PRENUP_STEP_ONE,
  PRENUP_STEP_TWO,
  COHABILITATION_STEP_ONE,
  COHABILITATION_STEP_TWO,
} = ROUTES;

export const isOnBoarding = (pathname) => {
    const onboardingRoutes = [
      TRIAL,
      ONBOARD,
      CHECKOUT,
      DIVORCE_STEP_ONE,
      DIVORCE_STEP_TWO,
      PRENUP_STEP_ONE,
      PRENUP_STEP_TWO,
      OTHER_STEP_ONE,
      COHABILITATION_STEP_ONE,
      COHABILITATION_STEP_TWO,
      CONFIRMATION,
    ];
    return onboardingRoutes.includes(pathname);
  };


  export const isAppRoutes = (pathname) => {
    const onboardingRoutes = [
      HOME,
      INCOME,
      ASSETS,
      LIABILITIES,
      SETTLEMENT,
      SETTLEMENT_ID,
      CHANGE_PROFILE,
      MONTHLY_EXPENSE,
      SETTLEMENT_CALCULATOR,
      '/assets/'
    ];
    return onboardingRoutes.includes(pathname);
  };