import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { OnboardService } from "../../../services/onboardService";
import {
  ESTIMATED_NET_WORTH,
  HIRED_PROFESSIONAL,
  BIGGEST_CHALLENGE_ENUM,
  HIRED_PROFESSIONAL_FEE,
  FINANCIAL_SETTLEMENT,
} from "../constants";
import { ROUTES } from "../../../routes/routes";
import { isEmpty } from "lodash";
import Error from "../common/Error";

const { DIVORCE_STEP_ONE, CONFIRMATION, PLANS } = ROUTES;
const { LESS_THAN_200K, BETWEEN_200K_AND_500K, MORE_THAN_500K } =
  ESTIMATED_NET_WORTH;
const {
  SETTLEMENT,
  AFFORD_ATTORNEY,
  PROTECT_CHILD_INTREST,
  PARALYZING_PROCESS,
} = BIGGEST_CHALLENGE_ENUM;
const { DIVORCE_ATTORNEY, MEDIATOR, CDFA, DIVORCE_COACH, NOT_HIRED_ANY } =
  HIRED_PROFESSIONAL;
const { LESS_THAN_5K, BETWEEN_5K_AND_10K, BETWEEN_10K_AND_20K, MORE_THAN_20K } =
  HIRED_PROFESSIONAL_FEE;
const { PROTECTING_ASSETS, FAIR_DIVISION, MARTIAL_HOME, CHILD_SPOUSE_SUPPORT } =
  FINANCIAL_SETTLEMENT;

const validationSchema = Yup.object().shape({
  estimatedNetWorth: Yup.string().required("Required field."),
  biggestChallenge: Yup.date().required("Required field."),
  hiredProfessional: Yup.string().required("Required field"),
  hiredProfessionalFee: Yup.string().required("Required field"),
  financialSettlement: Yup.string().required("Required field"),
});
const initialValues = {
  estimatedNetWorth: "",
  biggestChallenge: "",
  hiredProfessional: "",
  hiredProfessionalFee: "",
  financialSettlement: "",
};
const DivorceStepTwo = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(initialValues);
  const onboardService = new OnboardService();

  const formik = useFormik({ initialValues, validationSchema });
  const { values, setFieldValue, setValues } = formik;

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("onboardDetails"));
    if (
      !isEmpty(savedData) &&
      savedData?.currentStep == "step-2" &&
      savedData?.type == "divorce"
    ) {
      setValues({
        estimatedNetWorth: savedData?.estimatedNetWorth,
        biggestChallenge: savedData?.biggestChallenge,
        hiredProfessional: savedData?.hiredProfessional,
        hiredProfessionalFee: savedData?.hiredProfessionalFee,
        financialSettlement: savedData?.financialSettlement,
      });
    }
  }, []);

  const checkValidation = () => {
    let isErrorExists = false;
    const requiredFields = [
      "estimatedNetWorth",
      "biggestChallenge",
      "hiredProfessional",
      "hiredProfessionalFee",
      "financialSettlement",
    ];
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (isEmpty(values[field])) {
        newErrors[field] = "Required value";
        isErrorExists = true;
      }
    });
    setErrors(newErrors);
    if (isErrorExists) return false;
    else return true;
  };

  const onClickHandler = (keyName, value) => {
    resetError(keyName);
    setFieldValue(keyName, value);
  };

  const resetError = (keyname) => {
    setErrors({ ...errors, [keyname]: "" });
  };
  const handleContinue = async () => {
    try {
      if (!checkValidation()) {
        return;
      }
      const payload = { userId: user?.id, currentStep: "step-2", ...values };
      const { data } = await onboardService.update(payload);
      if (!isEmpty(data?.data)) {
        localStorage.setItem("onboardDetails", JSON.stringify(data.data));
        navigate(PLANS);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleBack = () => {
    navigate(DIVORCE_STEP_ONE);
  };

  return (
    <>
      <ToastContainer />
      <img className="m-4" src="assets/img/logo.svg" />
      <div className="avenue-step2"></div>
      <div className="container py-5 col-md-12">
        <Formik>
          <Form>
            <div className="row mb-3">
              <div className="row d-flex align-items-center mb-2">
                <div role="group" className="col-md-12">
                  <div className="mt-5 asset-label">
                    What’s your estimated household net worth?
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("estimatedNetWorth", LESS_THAN_200K)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.estimatedNetWorth === LESS_THAN_200K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.estimatedNetWorth === LESS_THAN_200K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.estimatedNetWorth === LESS_THAN_200K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.estimatedNetWorth === LESS_THAN_200K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Less than $200,000
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("estimatedNetWorth", BETWEEN_200K_AND_500K)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.estimatedNetWorth === BETWEEN_200K_AND_500K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.estimatedNetWorth === BETWEEN_200K_AND_500K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.estimatedNetWorth === BETWEEN_200K_AND_500K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.estimatedNetWorth === BETWEEN_200K_AND_500K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      $200,000 - $500,000
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("estimatedNetWorth", MORE_THAN_500K)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.estimatedNetWorth === MORE_THAN_500K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.estimatedNetWorth === MORE_THAN_500K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.estimatedNetWorth === MORE_THAN_500K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.estimatedNetWorth === MORE_THAN_500K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      More than $500,000
                    </label>
                  </div>
                  {errors.estimatedNetWorth && <Error />}

                  <div className="col-lg-9 mt-5">
                    <div className="asset-label mt-0 mb-0">
                      What’s the biggest challenge in your situation right now?
                    </div>
                    <div className="">
                      <div
                        onClick={() =>
                          onClickHandler("biggestChallenge", AFFORD_ATTORNEY)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-4 px-3 py-1 form-check ${
                          values.biggestChallenge === AFFORD_ATTORNEY
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.biggestChallenge === AFFORD_ATTORNEY
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.biggestChallenge === AFFORD_ATTORNEY
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.biggestChallenge === AFFORD_ATTORNEY
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          Affording an attorney
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("biggestChallenge",PROTECT_CHILD_INTREST)}
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-6 px-3 py-1 form-check ${
                          values.biggestChallenge === PROTECT_CHILD_INTREST
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.biggestChallenge === PROTECT_CHILD_INTREST
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.biggestChallenge === PROTECT_CHILD_INTREST
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.biggestChallenge === PROTECT_CHILD_INTREST
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          Protect the children’s best interests
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("biggestChallenge", SETTLEMENT)}
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-6 px-3 py-1 form-check ${
                          values.biggestChallenge === SETTLEMENT
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.biggestChallenge === SETTLEMENT
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.biggestChallenge === SETTLEMENT
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.biggestChallenge === SETTLEMENT
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          Figuring out the math for settlement
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("biggestChallenge", PARALYZING_PROCESS)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-6 px-3 py-1 form-check ${
                          values.biggestChallenge === PARALYZING_PROCESS
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.biggestChallenge === PARALYZING_PROCESS
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.biggestChallenge === PARALYZING_PROCESS
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.biggestChallenge === PARALYZING_PROCESS
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          Everything in this process is paralyzing
                        </label>
                      </div>
                    </div>
                    {errors.biggestChallenge && <Error />}
                  </div>
                  <div className="mt-5">
                    <div className="asset-label mt-0">
                      Have you hired any of the following?
                    </div>
                    <div className="">
                      <div
                        onClick={() =>
                          onClickHandler("hiredProfessional", DIVORCE_ATTORNEY)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-3 px-3 py-1 form-check ${
                          values.hiredProfessional === DIVORCE_ATTORNEY
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.hiredProfessional === DIVORCE_ATTORNEY
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.hiredProfessional === DIVORCE_ATTORNEY
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.hiredProfessional === DIVORCE_ATTORNEY
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          A divorce attorney
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("hiredProfessional", MEDIATOR)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-3 px-3 py-1 form-check ${
                          values.hiredProfessional === MEDIATOR
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.hiredProfessional === MEDIATOR
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.hiredProfessional === MEDIATOR ? 600 : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.hiredProfessional === MEDIATOR
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          A mediator
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("hiredProfessional", DIVORCE_COACH)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-3 px-3 py-1 form-check ${
                          values.hiredProfessional === DIVORCE_COACH
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.hiredProfessional === DIVORCE_COACH
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.hiredProfessional === DIVORCE_COACH
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.hiredProfessional === DIVORCE_COACH
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          A divorce coach
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("hiredProfessional", CDFA)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-5 px-3 py-1 form-check ${
                          values.hiredProfessional === CDFA
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.hiredProfessional === CDFA
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.hiredProfessional === CDFA ? 600 : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.hiredProfessional === CDFA
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          A certified divorce financial analyst (CDFA)
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("hiredProfessional", NOT_HIRED_ANY)
                        }
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-4 px-3 py-1 form-check ${
                          values.hiredProfessional === NOT_HIRED_ANY
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.hiredProfessional === NOT_HIRED_ANY
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.hiredProfessional === NOT_HIRED_ANY
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.hiredProfessional === NOT_HIRED_ANY
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          I have not hired anyone yet
                        </label>
                      </div>
                      {errors.hiredProfessional && <Error />}
                    </div>
                  </div>

                  <div className="mt-5 asset-label">
                    If you have hired professionals, how much have you spent on
                    professional services (attorney’s retainer fees/billed fees,
                    CDFA’s case fees, etc.)?
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("hiredProfessionalFee", LESS_THAN_5K)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.hiredProfessionalFee === LESS_THAN_5K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.hiredProfessionalFee === LESS_THAN_5K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.hiredProfessionalFee === LESS_THAN_5K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.hiredProfessionalFee === LESS_THAN_5K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Less than $5,000
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("hiredProfessionalFee", BETWEEN_5K_AND_10K)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.hiredProfessionalFee === BETWEEN_5K_AND_10K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.hiredProfessionalFee === BETWEEN_5K_AND_10K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.hiredProfessionalFee === BETWEEN_5K_AND_10K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.hiredProfessionalFee === BETWEEN_5K_AND_10K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      $5,000 - $10,000
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler(
                        "hiredProfessionalFee",
                        BETWEEN_10K_AND_20K
                      )
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.hiredProfessionalFee === BETWEEN_10K_AND_20K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.hiredProfessionalFee === BETWEEN_10K_AND_20K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.hiredProfessionalFee === BETWEEN_10K_AND_20K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.hiredProfessionalFee === BETWEEN_10K_AND_20K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      $10,000 - $20,000
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("hiredProfessionalFee", MORE_THAN_20K)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.hiredProfessionalFee === MORE_THAN_20K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.hiredProfessionalFee === MORE_THAN_20K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.hiredProfessionalFee === MORE_THAN_20K
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.hiredProfessionalFee === MORE_THAN_20K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      More than $20,000
                    </label>
                  </div>
                  {errors.hiredProfessionalFee && <Error />}
                  <div className="mt-5 asset-label">
                    What matters to you in the financial settlement?{" "}
                  </div>

                  <div
                    onClick={() =>
                      onClickHandler("financialSettlement", PROTECTING_ASSETS)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.financialSettlement === PROTECTING_ASSETS
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.financialSettlement === PROTECTING_ASSETS
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.financialSettlement === PROTECTING_ASSETS
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.financialSettlement === PROTECTING_ASSETS
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Protecting my assets
                    </label>
                  </div>

                  <div
                    onClick={() =>
                      onClickHandler("financialSettlement", FAIR_DIVISION)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.financialSettlement === FAIR_DIVISION
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.financialSettlement === FAIR_DIVISION
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.financialSettlement === FAIR_DIVISION
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.financialSettlement === FAIR_DIVISION
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Fair division of marital debt
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("financialSettlement", MARTIAL_HOME)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.financialSettlement === MARTIAL_HOME
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.financialSettlement === MARTIAL_HOME
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.financialSettlement === MARTIAL_HOME
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.financialSettlement === MARTIAL_HOME
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      The marital home
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("financialSettlement", CHILD_SPOUSE_SUPPORT)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-8 px-3 py-1 form-check ${
                      values.financialSettlement === CHILD_SPOUSE_SUPPORT
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.financialSettlement === CHILD_SPOUSE_SUPPORT
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.financialSettlement === CHILD_SPOUSE_SUPPORT
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.financialSettlement === CHILD_SPOUSE_SUPPORT
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Obtaining spousal maintenance and/or child support to
                      maintain quality of life
                    </label>
                  </div>
                  {errors.financialSettlement && <Error />}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-between order-lg-last order-md-last order-sm-first">
                <button
                  type="submit"
                  className="btn btn-size btn-secondary mb-sm-10 me-2"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-size btn-primary"
                  onClick={handleContinue}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default DivorceStepTwo;
