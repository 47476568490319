import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { ROUTES } from "../../routes/routes";

const Trial = () => {
    const navigate = useNavigate();

    const handlePay = () => {
        navigate(ROUTES.PLANS);
    }
  return (
    <>
      <img className="m-5" src="assets/img/logo.svg" />
      <div className="container d-flex py-5 col-md-12">
        <div className="ml-52 mt-104">
          <h2 className="heading-other-1">
            <p>Your free trial has ended.</p> 
            <p>Make a payment to continue accessing Anew.</p>
            </h2>
          <p className="mt-5 other-text">
            Please email us at amy@loveanew.co if you have any questions!
          </p>
          <button
                  type="button"
                  className="btn mt-5 btn-size btn-primary"
                  onClick={handlePay}
                >
                  Pay
                </button>

        </div>
      </div>
    </>
  );
};

export default Trial;
