import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class UserService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/user";
  }
  create(data) {
    return this.post(this.apiUrl, data);
  }
  getCount(conditions = {}) {
    return this.get(this.apiUrl + "/count" + this.objToQuery(conditions));
  }
  getAllUser(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }
  getUser(id) {
    return this.get(this.apiUrl + "/" + id);
  }
  deleteUser(id) {
    return this.delete(this.apiUrl + "/" + id);
  }
  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data);
  }
  updateProfile(data) {
    return this.patch(this.apiUrl, data);
  }
  changePasswords(data) {
    return this.post(this.apiUrl + "/change-password", data);
  }
}
