import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class AssetsService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/assets";
  }

  create(data) {
    return this.post(this.apiUrl, data);
  }
 
  getData(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }
  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data);
  }
}

