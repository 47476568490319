import React from "react";
import { Card, Button } from "react-bootstrap";

const PlanCard = ({
  title,
  heading,
  content1,
  content2,
  buttonText,
  features,
  clickHandler
}) => {
  return (
    <Card className="paymentCard">
      <Card.Body>
        <Card.Title className="cardTitle">{title}</Card.Title>
        <div className="text-center">
          <h1 className="cardHeading py-4">{heading}</h1>
          <p className="cardContent mb-0">{content1}</p>
          <p className="cardContent">{content2}</p>
          <div className="line-27 my-5"></div>
        </div>
        <ul style={{paddingLeft:'4px'}} className="cardContent">
          {features.map((feature, index) => (
            <div className="d-flex position-relative">
              <div className="list-icon">
                <img src="./assets/icons/list-icon.svg" />
              </div>
            <li style={{paddingLeft:'20px'}} className="list-unstyled pb-4" key={index}>
              {feature}
            </li>
            </div>
          ))}
        </ul>
        <div className="d-flex flex-column justify-content-center align-items-center py-3">
          <Button onClick={clickHandler} variant="primary" className="btn btn-size btn-primary">
            {buttonText}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PlanCard;
