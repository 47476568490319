import { SignUp } from "@clerk/clerk-react"
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { isEmpty } from "lodash";

const { SIGNUP } = ROUTES;

export default function SignUpPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(';', location);
    if(location.pathname == SIGNUP && !isEmpty(location.search)) {
      navigate(SIGNUP);
    }
  }, [])

  return <SignUp signInUrl="/login" path="/signup" />;
}
