export const BasicFeatures = [
  "Access to Anew’s financial disclosure & settlement planning tools",
  "Assets & debts summary",
  "Lifestyle analysis: income & expenses report",
  "Home equity split calculation",
  "Settlement planning tools",
];

export const AdvancedFeatures = [
  "Instant access to 90-page Financial Discovery Guide.",
  "Ways to make discovery demands",
  "Worksheet for discovery planning ",
  "Instructions on making a strong case",
];

export const TermsAndConditions = [
  "Basic Plan refund policy: you won’t be charged during the 7-day trial. If you cancel after the trial period ends, there will be no refund and your subscription will continue to be active until the end of your current billing cycle.",
  "Advanced Plan refund policy: due to the immediate access of digital content, including all electronic materials, videos, guides, worksheets, etc, all sales are final and there will be no refund.",
  "For personal use only:  Anew’s Products are for personal use only. You are granted one (1) non-transferable, non-sublicensable, non-exclusive license to use Anew’s Products you have purchased through the website. You agree not to copy, reproduce, redistribute, alter, modify, share with third parties, display the content publicly, or create derivative works of Anew’s products.",
  "Indemnity: Anew’s products and plans are for general interest, education, and informational use only and do not include legal or financial advice. Errors, inaccuracies and omissions may occur. You agree to indemnify and hold Anew, its officers, directors, advisors and employees harmless from any losses (including attorneys’ fees) related to your use or misuse of Anew’s products.  In no event shall Anew be liable for any special, indirect, incidental, consequential, punitive or exemplary damages or for loss of profits, revenues, contracts, loss of use, loss of data, business interruption, or cost of replacement goods related to or in connection with the use of Anew’s products.",
];

export const PLAN_TYPES = {
  BASIC: 'Basic',
  ADVANCED: 'Advanced',
}