import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { OnboardService } from "../../../services/onboardService";
import { YOU_STAND_ENUM, CURRENT_RELATIONSHIP_STATE } from "../constants";
import { ROUTES } from "../../../routes/routes";
import { isEmpty } from "lodash";
import moment from "moment";
import Error from "../common/Error";

const { DIVORCE_STEP_TWO, ONBOARD } = ROUTES;
const { NOT_WANT_DIVORCE, THINKING, READY } = YOU_STAND_ENUM;
const { GOOD_TERM, SPEAKING_WELL, SOME_AVERAGE, TOUGH_CONFLICT } =
  CURRENT_RELATIONSHIP_STATE;

const validationSchema = Yup.object().shape({
  filedForDivorce: Yup.string().required("Required field."),
  filingDate: Yup.date().required("Required field."),
  state: Yup.string().optional(),
  whereYouStand: Yup.string().required("Required field"),
  yourSpouseStand: Yup.string().required("Required field"),
  spousesMonthlyIncome: Yup.string().required("Required field"),
});
const initialValues = {
  filedForDivorce: "",
  filingDate: "",
  state: "",
  whereYouStand: "",
  yourSpouseStand: "",
  currentRelationshipState: "",
};

const DivorceStepOne = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const onboardService = new OnboardService();
  const [error, setError] = useState(initialValues);

  const formik = useFormik({ initialValues, validationSchema });
  const { values, setFieldValue, setValues } = formik;

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("onboardDetails"));
    if (
      !isEmpty(savedData) &&
      savedData?.currentStep !== "step-0" &&
      savedData?.type == "divorce"
    ) {
      const date = moment(savedData?.filingDate);
      setValues({
        filedForDivorce: savedData?.filedForDivorce,
        filingDate: date.format("YYYY-MM-DD"),
        state: savedData?.state,
        whereYouStand: savedData?.whereYouStand,
        yourSpouseStand: savedData?.yourSpouseStand,
        currentRelationshipState: savedData?.currentRelationshipState,
      });
    }
  }, []);

  const checkValidation = () => {
    let isErrorExists = false;
    const requiredFields = [
      "filedForDivorce",
      "state",
      "whereYouStand",
      "yourSpouseStand",
      "currentRelationshipState",
    ];
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (isEmpty(values[field]) || values[field] == "Invalid date") {
        newErrors[field] = "Required value";
        isErrorExists = true;
      }
    });
    setError(newErrors);
    if (isErrorExists) return false;
    else return true;
  };

  const onClickHandler = (keyName, value) => {
    resetError(keyName);
    setFieldValue(keyName, value);
  };

  const resetError = (keyname) => {
    setError({ ...error, [keyname]: "" });
  };

  const handleContinue = async () => {
    try {
      // if (isEmpty(values.filingDate)) {
      //   setError({ ...error, filingDate: "Required value" });
      //   return;
      // }
      if (!checkValidation()) {
        return;
      }
      const payload = { userId: user?.id, currentStep: "step-1", ...values };
      const { data } = await onboardService.update(payload);
      if (!isEmpty(data?.data)) {
        localStorage.setItem("onboardDetails", JSON.stringify(data.data));
        navigate(DIVORCE_STEP_TWO);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleBack = () => {
    navigate(ONBOARD);
  };

  return (
    <>
      <ToastContainer />
      <img className="m-4" src="assets/img/logo.svg" />
      <div className="avenue-step1"></div>
      <div className="container py-5 col-md-12">
        <Formik>
          <Form>
            <div className="row my-2">
              <div className="row d-flex align-items-center mb-2">
                <div role="group" className="col-md-12">
                  <div className="mt-3 asset-label">
                    Have you filed for divorce?
                  </div>
                  <div
                    onClick={() => onClickHandler("filedForDivorce", "yes")}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.filedForDivorce === "yes"
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.filedForDivorce === "yes"
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.filedForDivorce === "yes" ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.filedForDivorce === "yes"
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Yes
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("filedForDivorce", "no")}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.filedForDivorce === "no"
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.filedForDivorce === "no"
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight: values.filedForDivorce === "no" ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.filedForDivorce === "no"
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      No
                    </label>
                  </div>
                  {error.filedForDivorce && <Error />}
                  <div className="col-md-3 mt-5">
                    <label className="asset-label mt-0">Filing Date</label>
                    <input
                      type="Date"
                      name="filingDate"
                      className="form-control"
                      onChange={(e) => {
                        setError({ ...error, filingDate: "" });
                        setFieldValue("filingDate", e.target.value);
                      }}
                      value={values.filingDate}
                    />
                    {/* {!isEmpty(error.filingDate) && (
                      <div className="text-danger">
                        <p>{"Required value"}</p>
                      </div>
                    )} */}
                  </div>
                  <div className="col-md-3 mt-5">
                    <label className="asset-label mt-0">
                      Which state you are in
                    </label>
                    <input
                      name={"state"}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setError({ ...error, state: "" });
                        setFieldValue("state", e.target.value);
                      }}
                      value={values.state}
                    />
                    {error.state && <Error />}
                  </div>
                  <div className="col-md-9 mt-5">
                    <div className="asset-label mt-0 mb-0">
                      Where do you stand
                    </div>
                    <div className="d-flex">
                      <div
                        onClick={() =>
                          onClickHandler("whereYouStand", NOT_WANT_DIVORCE)
                        }
                        className={`mt-2 col-md-4 d-flex border-l justify-content-center align-items-center px-3 form-check ${
                          values.whereYouStand === NOT_WANT_DIVORCE
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.whereYouStand === NOT_WANT_DIVORCE
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.whereYouStand === NOT_WANT_DIVORCE
                                ? 600
                                : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          I am not the one who wants to get divorced
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("whereYouStand", THINKING)
                        }
                        className={`mt-2 col-md-4 px-3 d-flex justify-content-center align-items-center form-check ${
                          values.whereYouStand === THINKING
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.whereYouStand === THINKING
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.whereYouStand === THINKING ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Still thinking about it
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("whereYouStand", READY)}
                        className={`mt-2 col-md-4 px-3 border-r d-flex justify-content-center align-items-center form-check ${
                          values.whereYouStand === READY
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.whereYouStand === READY
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.whereYouStand === READY ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Ready to move on
                        </label>
                      </div>
                    </div>
                    {error.whereYouStand && <Error />}
                  </div>
                  <div className="col-md-9 mt-5">
                    <div className="asset-label mt-0 mb-0">
                      What about your spouse
                    </div>
                    <div className="d-flex">
                      <div
                        onClick={() =>
                          onClickHandler("yourSpouseStand", NOT_WANT_DIVORCE)
                        }
                        className={`mt-2 col-md-4 d-flex border-l justify-content-center align-items-center px-3 form-check ${
                          values.yourSpouseStand === NOT_WANT_DIVORCE
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.yourSpouseStand === NOT_WANT_DIVORCE
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.yourSpouseStand === NOT_WANT_DIVORCE
                                ? 600
                                : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          I am not the one who wants to get divorced
                        </label>
                      </div>
                      <div
                        onClick={() =>
                          onClickHandler("yourSpouseStand", THINKING)
                        }
                        className={`mt-2 col-md-4 px-3 d-flex justify-content-center align-items-center form-check ${
                          values.yourSpouseStand === THINKING
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.yourSpouseStand === THINKING
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.yourSpouseStand === THINKING ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Still thinking about it
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("yourSpouseStand", READY)}
                        className={`mt-2 col-md-4 px-3 border-r d-flex justify-content-center align-items-center form-check ${
                          values.yourSpouseStand === READY
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.yourSpouseStand === READY
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.yourSpouseStand === READY ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Ready to move on
                        </label>
                      </div>
                    </div>
                    {error.yourSpouseStand && <Error />}
                  </div>
                  <div className="mt-5 asset-label mt-0">
                    What is your current state of your relationship
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("currentRelationshipState", GOOD_TERM)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 border-rounded-5 form-check ${
                      values.currentRelationshipState === GOOD_TERM
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.currentRelationshipState === GOOD_TERM
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.currentRelationshipState === GOOD_TERM
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.currentRelationshipState === GOOD_TERM
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      On good terms, communication well
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("currentRelationshipState", SPEAKING_WELL)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.currentRelationshipState === SPEAKING_WELL
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.currentRelationshipState === SPEAKING_WELL
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.currentRelationshipState === SPEAKING_WELL
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.currentRelationshipState === SPEAKING_WELL
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      We are speaking but that's about it
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("currentRelationshipState", SOME_AVERAGE)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.currentRelationshipState === SOME_AVERAGE
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.currentRelationshipState === SOME_AVERAGE
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.currentRelationshipState === SOME_AVERAGE
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.currentRelationshipState === SOME_AVERAGE
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Get along some days but not others
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler("currentRelationshipState", TOUGH_CONFLICT)
                    }
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.currentRelationshipState === TOUGH_CONFLICT
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.currentRelationshipState === TOUGH_CONFLICT
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.currentRelationshipState === TOUGH_CONFLICT
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.currentRelationshipState === TOUGH_CONFLICT
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Things are tough - lots of conflict
                    </label>
                  </div>
                  {error.currentRelationshipState && <Error />}
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-3 ">
              <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-between order-lg-last order-md-last order-sm-first">
                <button
                  type="submit"
                  className="btn btn-size btn-secondary mb-sm-10 me-2"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-size btn-primary"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default DivorceStepOne;
