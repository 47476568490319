export const ROUTES = {
  INCOME: '/income',
  LIABILITIES: '/liabilities',
  MONTHLY_EXPENSE: '/monthly-expense',
  ASSETS: '/assets',
  SETTLEMENT: '/settlement',
  SETTLEMENT_ID: '/settlement/:id',
  SETTLEMENT_CALCULATOR: '/settlementCalculation',
  CHANGE_PROFILE: '/change-profile',
  
  ONBOARD: "/onboard",

  DIVORCE_STEP_ONE: "/divorce-step-one",
  DIVORCE_STEP_TWO: "/divorce-step-two",

  PRENUP_STEP_ONE: "/prenup-step-one",
  PRENUP_STEP_TWO: "/prenup-step-two",

  COHABILITATION_STEP_ONE: "/cohabilitation-step-one",
  COHABILITATION_STEP_TWO: "/cohabilitation-step-two",

  OTHER_STEP_ONE: "/other-step-one",
  CONFIRMATION: "/confirmation",

  PLANS: '/plans',
  CHECKOUT: "/checkout",
  CHECKOUT_PLANID: "/checkout/:planId",
  TRIAL: '/trial',
  
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
};
