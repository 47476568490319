import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { INDIVIDUAL_INCOME_RANGE, TOTAL_NET_WORTH } from "../constants";
import { ROUTES } from "../../../routes/routes";
import { isEmpty } from "lodash";
import { OnboardService } from "../../../services/onboardService";
import Error from "../common/Error";

const { COHABILITATION_STEP_ONE, CONFIRMATION } = ROUTES;
const { LESS_THAN_70K, BETWEEN_70K_AND_150K, MORE_THAN_150K } = INDIVIDUAL_INCOME_RANGE
const { LESS_THAN_200K, MORE_THAN_200K } = TOTAL_NET_WORTH;


const validationSchema = Yup.object().shape({
  individualIncomeRange: Yup.string().required("Required field."),
  totalNetWorth: Yup.date().required("Required field."),
});

const initialValues = {
  individualIncomeRange: "",
  totalNetWorth: "",
};

const CohabilitationStepTwo = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(initialValues);

  const onboardService = new OnboardService();

  const formik = useFormik({initialValues, validationSchema});
  const { values, setFieldValue, setValues } = formik;

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("onboardDetails"));
    if (
      !isEmpty(savedData) &&
      savedData?.currentStep == "step-2" &&
      savedData?.type == "cohabilitation"
    ) {
      setValues({
        individualIncomeRange: savedData.individualIncomeRange,
        totalNetWorth:savedData?.totalNetWorth,
      });
    }
  }, []);

  const checkValidation = () => {
    let isErrorExists = false;
    const requiredFields = ["individualIncomeRange", "totalNetWorth"];
    const newErrors = {};
    requiredFields.forEach(field => {
      if (isEmpty(values[field])) {
        newErrors[field] = "Required value";
        isErrorExists = true;
      }
    });
    setErrors(newErrors);
    if(isErrorExists) return false;
    else return true;
  };

  const handleContinue = async () => {
    try {
        if(!checkValidation()) {
          return;
        }
      const payload = { userId: user?.id, currentStep: "step-2", ...values };
      const { data } = await onboardService.update(payload);
      if (!isEmpty(data?.data)) {
        localStorage.setItem('onboardDetails', JSON.stringify(data.data));
        navigate(CONFIRMATION);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const resetError = (keyname) => {
    setErrors({ ...errors, [keyname]: "" });
  }

  const onClickHandler = (keyName, value) => {
    resetError(keyName);
    setFieldValue(keyName, value);
  }
  const handleBack = () => {
    navigate(COHABILITATION_STEP_ONE);
  }
  return (
    <>
      <ToastContainer />
      <img className="m-4" src="assets/img/logo.svg" />
      <div className="avenue-step2"></div>
      <div className="container py-5 col-md-12">
        <Formik>
          <Form>
            <div className="row my-2">
              <div className="row d-flex align-items-center mb-2">
                <div role="group" className="col-md-12">
                  <div className="asset-label">What’s your individual income range?</div>
                  <div
                    onClick={() => onClickHandler("individualIncomeRange", LESS_THAN_70K)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.individualIncomeRange === LESS_THAN_70K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.individualIncomeRange === LESS_THAN_70K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.individualIncomeRange === LESS_THAN_70K ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.individualIncomeRange === LESS_THAN_70K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Less than $70,000
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("individualIncomeRange", BETWEEN_70K_AND_150K)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.individualIncomeRange === BETWEEN_70K_AND_150K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.individualIncomeRange === BETWEEN_70K_AND_150K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight: values.individualIncomeRange === BETWEEN_70K_AND_150K ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.individualIncomeRange === BETWEEN_70K_AND_150K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      $70,000 - $150,000
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("individualIncomeRange", MORE_THAN_150K)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.individualIncomeRange === MORE_THAN_150K
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.individualIncomeRange === MORE_THAN_150K
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight: values.individualIncomeRange === MORE_THAN_150K ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.individualIncomeRange === MORE_THAN_150K
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      More than $150,000
                    </label>
                  </div>
                  {errors.individualIncomeRange && <Error />}
                  <div className="col-lg-6 mt-5 mb-3">
                    <div className="asset-label">What's your total net worth?</div>
                    <div className="d-flex">
                      <div
                        onClick={() => onClickHandler("totalNetWorth", LESS_THAN_200K)}
                        className={`mt-2 col-md-5 py-2 d-flex border-l justify-content-center align-items-center px-3 form-check ${
                          values.totalNetWorth === LESS_THAN_200K
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.totalNetWorth === LESS_THAN_200K
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.totalNetWorth === LESS_THAN_200K
                                ? 600
                                : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                         Less than $200,000
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("totalNetWorth", MORE_THAN_200K)}
                        className={`mt-2 col-md-5 py-2 px-3 d-flex border-r justify-content-center align-items-center form-check ${
                          values.totalNetWorth === MORE_THAN_200K
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.totalNetWorth === MORE_THAN_200K
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.totalNetWorth === MORE_THAN_200K ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          More than $200,000
                        </label>
                      </div>
                    </div>
                    {errors.totalNetWorth && <Error />}
                  </div>


                  {formik.touched.yourAnnualGross &&
                    formik.errors.yourAnnualGross && (
                      <div className="text-danger">
                        {formik.errors.yourAnnualGross}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-between order-lg-last order-md-last order-sm-first">
                <button
                  type="submit"
                  className="btn btn-size btn-secondary mb-sm-10 me-2"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-size btn-primary"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default CohabilitationStepTwo;
