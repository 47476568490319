import React from 'react';
import { UserProfile } from "@clerk/clerk-react";

const User = () => {

    return (
        <>
            <UserProfile path="/user-profile" />
        </>
    );
};

export default User;
