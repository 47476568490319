import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  RELATIONSHIP_DURATION,
  PRENUP_PRIORITIES,
  PRENUP_WHAT_APPLIES,
} from "../constants";
import { ROUTES } from "../../../routes/routes";
import { OnboardService } from "../../../services/onboardService";
import { isEmpty } from "lodash";
import moment from "moment";
import Error from "../common/Error";

const { PRENUP_STEP_TWO, ONBOARD } = ROUTES;
const { PAYING_CHILD_SUPPORT, RECEIVING_CHILD_SUPPORT } = PRENUP_WHAT_APPLIES;
const {
  LESS_THAN_ONE_YEAR,
  ONE_THREE_YEAR,
  FIVE_SEVEN_YEAR,
  MORE_THAN_SEVEN_YEAR,
} = RELATIONSHIP_DURATION;
const {
  PROTECTING_ASSETS,
  PROTECTING_INHERITANCE,
  HANDLING_DEBTS,
  SHARING_FINANCES,
  OTHER,
} = PRENUP_PRIORITIES;

const validationSchema = Yup.object().shape({
  weedingDate: Yup.string().required("Required field."),
  state: Yup.date().optional("Required field."),
  howLongTogether: Yup.string().required("Required field"),
  prioritiesInPrenup: Yup.string().required("Required field"),
  livingTogether: Yup.string().required("Required field"),
  sharingFinancesAlready: Yup.string().required("Required field"),
  marriedBefore: Yup.string().required("Required field"),
  whatCheckApplies: Yup.string().required("Required field"),
  haveChildren: Yup.string().required("Required field"),
});
const initialValues = {
  weedingDate: "",
  state: "",
  howLongTogether: "",
  prioritiesInPrenup: "",
  livingTogether: "",
  sharingFinancesAlready: "",
  marriedBefore: "",
  whatCheckApplies: "",
  haveChildren: "",
};

const PrenupStepOne = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const onboardService = new OnboardService();
  const [error, setError] = useState({
    weedingDate: "",
    state: "",
    howLongTogether: "",
    prioritiesInPrenup: "",
    livingTogether: "",
    sharingFinancesAlready: "",
    marriedBefore: "",
    whatCheckApplies: "",
    haveChildren: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.userId = user.id;
    },
  });
  const { values, setValues, setFieldValue } = formik;

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('onboardDetails'));
    if(!isEmpty(savedData) && savedData?.currentStep !=='step-0' && savedData?.type=='prenup') {
      const date = moment(savedData?.weedingDate);
      setValues({
        weedingDate:  date.format('YYYY-MM-DD'),
        state: savedData?.state,
        howLongTogether: savedData?.howLongTogether,
        prioritiesInPrenup: savedData?.prioritiesInPrenup,
        livingTogether: savedData?.livingTogether,
        sharingFinancesAlready: savedData?.sharingFinancesAlready,
        marriedBefore: savedData?.marriedBefore,
        whatCheckApplies: savedData?.whatCheckApplies,
        haveChildren: savedData?.haveChildren,
      })
    }

  }, []);

  const checkValidation = () => {
    let isErrorExists = false;
    const requiredFields = [
      "weedingDate",
      "state",
      "howLongTogether",
      "prioritiesInPrenup",
      "livingTogether",
      "sharingFinancesAlready",
      "whatCheckApplies",
      "marriedBefore",
      "haveChildren"
    ];
    const newErrors = {};
    requiredFields.forEach(field => {
      if (isEmpty(values[field]) || values[field] == 'Invalid date') {
        newErrors[field] = "Required value";
        isErrorExists = true;
      }
    });
    setError(newErrors);
    if(isErrorExists) return false;
    else return true;
  };

  const onClickHandler = (keyName, value) => {
    resetError(keyName);
    setFieldValue(keyName, value);
  }

  const resetError = (keyname) => {
    setError({ ...error, [keyname]: "" });
  }

  const handleContinue = async () => {
    try {
      if(!checkValidation() && values.marriedBefore !== "no"){
        return;
      }

      const payload = { userId: user?.id, currentStep: "step-1", ...values };
      if(values?.marriedBefore == "no") {
        delete payload.whatCheckApplies;
      }
      const { data } = await onboardService.update(payload);
      if (!isEmpty(data?.data)) {
        localStorage.setItem('onboardDetails', JSON.stringify(data.data));
        navigate(PRENUP_STEP_TWO);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleBack = () => {
    navigate(ONBOARD);
  };
  console.log('err', error);
  console.log('err-values', values);
  return (
    <>
      <ToastContainer />
      <img className="m-4" src="assets/img/logo.svg" />
      <div className="avenue-step1"></div>
      <div className="container col-md-12">
        <Formik>
          <Form>
            <div className="row my-2">
              <div className="row d-flex align-items-center">
                <div role="group" className="col-md-12">
                  <div className="col-md-3 mt-5">
                    <label className="asset-label">
                      When is the wedding day?
                    </label>
                    <input
                      type="Date"
                      name="weedingDate"
                      className="form-control"
                      onChange={(e) => {
                        setError({ ...error, weedingDate: "" });
                        setFieldValue("weedingDate", e.target.value);
                      }}
                      value={values.weedingDate}
                    />
                    {error.weedingDate && <Error />}
                  </div>
                  <div className="col-md-3 mt-5">
                    <label className="asset-label mt-0">
                      Which state you are in?
                    </label>
                    <input
                      name="state"
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setError({ ...error, state: "" });
                        setFieldValue("state", e.target.value);
                      }}
                      value={values.state}
                    />
                    {error.state && <Error />}
                  </div>
                  <div className="mt-5 asset-label mt-0">How long you have been together?</div>
                  <div
                    onClick={() => onClickHandler("howLongTogether", LESS_THAN_ONE_YEAR)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 border-rounded-5 form-check ${
                      values.howLongTogether === LESS_THAN_ONE_YEAR
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.howLongTogether === LESS_THAN_ONE_YEAR
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.howLongTogether === LESS_THAN_ONE_YEAR
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.howLongTogether === LESS_THAN_ONE_YEAR
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Less than 1 year
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("howLongTogether", ONE_THREE_YEAR)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.howLongTogether === ONE_THREE_YEAR
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.howLongTogether === ONE_THREE_YEAR
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.howLongTogether === ONE_THREE_YEAR ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.howLongTogether === ONE_THREE_YEAR
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      1-3 years
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("howLongTogether", FIVE_SEVEN_YEAR)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.howLongTogether === FIVE_SEVEN_YEAR
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.howLongTogether === FIVE_SEVEN_YEAR
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.howLongTogether === FIVE_SEVEN_YEAR
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.howLongTogether === FIVE_SEVEN_YEAR
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      5-7 years
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("howLongTogether", MORE_THAN_SEVEN_YEAR)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-3 px-3 py-1 form-check ${
                      values.howLongTogether === MORE_THAN_SEVEN_YEAR
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.howLongTogether === MORE_THAN_SEVEN_YEAR
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.howLongTogether === MORE_THAN_SEVEN_YEAR
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.howLongTogether === MORE_THAN_SEVEN_YEAR
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      More than 7 years
                    </label>
                  </div>
                  {error.howLongTogether && <Error />}
                  <div className="mt-5 asset-label mt-0">
                    What are your priorities in a prenup?
                  </div>
                  <div
                    onClick={() => onClickHandler("prioritiesInPrenup", PROTECTING_ASSETS)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-8 px-3 py-1 border-rounded-5 form-check ${
                      values.prioritiesInPrenup === PROTECTING_ASSETS
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.prioritiesInPrenup === PROTECTING_ASSETS
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.prioritiesInPrenup === PROTECTING_ASSETS
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.prioritiesInPrenup === PROTECTING_ASSETS
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Protecting my assets(savings/checking, stock options, real
                      estate property, etc.)
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("prioritiesInPrenup", PROTECTING_INHERITANCE)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.prioritiesInPrenup === PROTECTING_INHERITANCE
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.prioritiesInPrenup === PROTECTING_INHERITANCE
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.prioritiesInPrenup === PROTECTING_INHERITANCE
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.prioritiesInPrenup === PROTECTING_INHERITANCE
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Protecting my inheritance
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("prioritiesInPrenup", HANDLING_DEBTS)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.prioritiesInPrenup === HANDLING_DEBTS
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.prioritiesInPrenup === HANDLING_DEBTS
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.prioritiesInPrenup === HANDLING_DEBTS
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.prioritiesInPrenup === HANDLING_DEBTS
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Handling my premartial debts
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("prioritiesInPrenup", SHARING_FINANCES)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 form-check ${
                      values.prioritiesInPrenup === SHARING_FINANCES
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.prioritiesInPrenup === SHARING_FINANCES
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.prioritiesInPrenup === SHARING_FINANCES
                            ? 600
                            : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.prioritiesInPrenup === SHARING_FINANCES
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Set the rules for sharing finances
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("prioritiesInPrenup", OTHER)}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-6 px-3 py-1 form-check ${
                      values.prioritiesInPrenup === OTHER
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.prioritiesInPrenup === OTHER
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.prioritiesInPrenup === OTHER ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.prioritiesInPrenup === OTHER
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Other (planing for kids, punishing adultery, etc.)
                    </label>
                  </div>
                  {error.prioritiesInPrenup && <Error />}

                  <div className="col-lg-4 mt-5">
                    <div className="asset-label mt-0 mb-0">Are you living together</div>
                    <div className="d-flex">
                      <div
                        onClick={() => onClickHandler("livingTogether", "yes")}
                        className={`mt-2 py-2 col-md-4  d-flex border-l justify-content-center align-items-center px-3 form-check ${
                          values.livingTogether === "yes"
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.livingTogether === "yes"
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.livingTogether === "yes" ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Yes
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("livingTogether", "no")}
                        className={`mt-2 py-2 col-md-4 px-3 d-flex border-r justify-content-center align-items-center form-check ${
                          values.livingTogether === "no"
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.livingTogether === "no"
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.livingTogether === "no" ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {error.livingTogether && <Error />}
                  </div>
                  <div className="col-lg-4 mt-5">
                    <div className="asset-label mt-0 mb-0">Are you sharing finances already?</div>
                    <div className="d-flex">
                      <div
                        onClick={() => onClickHandler("sharingFinancesAlready", "yes")}
                        className={`mt-2 py-2 col-md-4 d-flex border-l justify-content-center align-items-center px-3 form-check ${
                          values.sharingFinancesAlready === "yes"
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.sharingFinancesAlready === "yes"
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.sharingFinancesAlready === "yes"
                                ? 600
                                : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Yes
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("sharingFinancesAlready", "no")}
                        className={`mt-2 py-2 col-md-4 d-flex border-r justify-content-center align-items-center px-3 form-check ${
                          values.sharingFinancesAlready === "no"
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.sharingFinancesAlready === "no"
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.sharingFinancesAlready === "no"
                                ? 600
                                : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {error.sharingFinancesAlready && <Error />}
                  </div>
                  <div className="col-lg-4 mt-5">
                    <div className="asset-label mt-0 mb-0"> Have you been married before?</div>
                    <div className="d-flex">
                      <div
                        onClick={() => onClickHandler("marriedBefore", "yes")}
                        className={`mt-2 py-2 col-md-4 d-flex border-l justify-content-center align-items-center px-3 form-check ${
                          values.marriedBefore === "yes"
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.marriedBefore === "yes"
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.marriedBefore === "yes" ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          Yes
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("marriedBefore", "no")}
                        className={`mt-2 py-2 col-md-4 border-r px-3 d-flex justify-content-center align-items-center form-check ${
                          values.marriedBefore === "no"
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.marriedBefore === "no"
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.marriedBefore === "no" ? 600 : 400,
                          }}
                          className="form-check-label pt-1 gap-2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {error.marriedBefore && <Error />}
                  </div>
                  {values?.marriedBefore == "yes" && (
                    <>
                      <div className="mt-5 asset-label mt-0">if yes, check what applies</div>
                      <div
                        onClick={() => onClickHandler("whatCheckApplies", PAYING_CHILD_SUPPORT)}
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-5 px-3 py-1 border-rounded-5 form-check ${
                          values.whatCheckApplies === PAYING_CHILD_SUPPORT
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.whatCheckApplies === PAYING_CHILD_SUPPORT
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.whatCheckApplies === PAYING_CHILD_SUPPORT
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.whatCheckApplies === PAYING_CHILD_SUPPORT
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          I'm paying alimony and/or child support
                        </label>
                      </div>
                      <div
                        onClick={() => onClickHandler("whatCheckApplies", RECEIVING_CHILD_SUPPORT)}
                        style={{ borderRadius: "10px" }}
                        className={`mt-2 col-md-5 px-3 py-1 form-check ${
                          values.whatCheckApplies === RECEIVING_CHILD_SUPPORT
                            ? "bg-light-green"
                            : "bg-silver-gray"
                        }`}
                      >
                        <label
                          style={{
                            color:
                              values.whatCheckApplies ===
                              RECEIVING_CHILD_SUPPORT
                                ? "#014921"
                                : "rgba(0,0,0,50%)",
                            fontWeight:
                              values.whatCheckApplies ===
                              RECEIVING_CHILD_SUPPORT
                                ? 600
                                : 400,
                          }}
                          className="form-check-label d-flex gap-2 py-1"
                        >
                          <img
                            src={`/assets/icons/${
                              values.whatCheckApplies ===
                              RECEIVING_CHILD_SUPPORT
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"
                            } `}
                            alt=""
                          />
                          I'm receiving alimony and/or child support
                        </label>
                      </div>
                      {error.whatCheckApplies && <Error />}
                    </>
                  )}

                  <div className="mt-5 asset-label mt-0">Do you have children?</div>
                  <div
                    onClick={() => onClickHandler("haveChildren", "no")}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 border-rounded-5 form-check ${
                      values.haveChildren === "no"
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.haveChildren === "no"
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight: values.haveChildren === "no" ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.haveChildren === "no"
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      No
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("haveChildren", "yes-current")}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-4 px-3 py-1 border-rounded-5 form-check ${
                      values.haveChildren === "yes-current"
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.haveChildren === "yes-current"
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.haveChildren === "yes-current" ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.haveChildren === "yes-current"
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Yes, with my current partner
                    </label>
                  </div>
                  <div
                    onClick={() => onClickHandler("haveChildren", "yes-previous")}
                    style={{ borderRadius: "10px" }}
                    className={`mt-2 col-md-6 px-3 py-1 form-check ${
                      values.haveChildren === "yes-previous"
                        ? "bg-light-green"
                        : "bg-silver-gray"
                    }`}
                  >
                    <label
                      style={{
                        color:
                          values.haveChildren === "yes-previous"
                            ? "#014921"
                            : "rgba(0,0,0,50%)",
                        fontWeight:
                          values.haveChildren === "yes-previous" ? 600 : 400,
                      }}
                      className="form-check-label d-flex gap-2 py-1"
                    >
                      <img
                        src={`/assets/icons/${
                          values.haveChildren === "yes-previous"
                            ? "radio-checked.svg"
                            : "radio-unchecked.svg"
                        } `}
                        alt=""
                      />
                      Yes, with my previous partner or through adoption
                    </label>
                  </div>
                  {error.haveChildren && <Error />}
                </div>
              </div>
            </div>
            <div className="row mb-4 mt-4">
              <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-between order-lg-last order-md-last order-sm-first">
                <button
                  type="submit"
                  className="btn btn-size btn-secondary mb-sm-10 me-2"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-size btn-primary"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default PrenupStepOne;
