import React, { useState } from "react";
import {
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
  useStripe,
  useElements,
  AddressElement
} from "@stripe/react-stripe-js";
import { useUser } from "@clerk/clerk-react";
import { ToastContainer, toast } from 'react-toastify';
import { PlansService } from "../../../services/plans.service";
import { isEmpty } from "lodash";
import "./checkout.css";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";

const CheckoutForm = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();
  const stripe = useStripe();
  const elements = useElements();

  const { planId } = params;
  const { couponDetails } = props;
  const planService = new PlansService();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cardName, setCardName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [secureMyInfo, setSecureMyInfo] = useState(false);
  const [stripeErr, setStripeErr] = useState(null);

  const upgradePlan = async (payId) => {
    try {
      const payload = {
        planId: planId,
        payId: payId,
        userId: user.id,
      }
      if(!isEmpty(couponDetails)) {
        payload.coupon = couponDetails.id
      }
  
      const { data } = await planService.purchasePlan(payload);
      console.log('data', data);
      if(data) {
        toast.success('Subscription plan purchased successfully')
        setLoading(false);
        setTimeout(() => {
          navigate(ROUTES.HOME);
        }, 2000);
      }

    } catch(err) {
      console.log('err', err);
      setLoading(false);
      toast.error(err.response.data?.message??"something went wrong");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe?.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        email: email,
        name: cardName,
      },
      metadata: {
        userId: user?.id
      },
    });

    if (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.message ?? 'something went wrong');
      setStripeErr(error);
    } else {
      console.log('paymentMethod', paymentMethod);
      // Handle successful payment, e.g., send paymentMethod.id to your server
      await upgradePlan(paymentMethod.id);
    }
  };

  console.log('setError', stripeErr);

  const allowToPay = () => {
    return termsAndCondition && !loading && isEmpty(stripeErr);
  }
  const addressElementOptions = {
    style: {
      base: {
        color: '#1a1a1ab3 !important',
        fontFamily: 'Inter !important',
        fontWeight: '500',
        fontSize: '16px',
      },
      invalid: {
        color: '#ff0000',
      },
    },
  };

  return (
    <div className="card p-5">
      <ToastContainer />
      <form className="stripe-checkout-form" onSubmit={handleSubmit}>
        <div className="mb-3 payment-main-h">{"Enter payment details"}</div>
        <div className="labels-h">{"Email Address"}</div>
        <div className="mb-3 relative">
          <input
            type="email"
            className="form-control"
            placeholder={"example@example.com"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(""); // Clear email error when input changes
            }}
            required
          />
          {emailError && <div className="text-danger mt-2">{emailError}</div>}
        </div>
        <div className="labels-h">{"Phone Number"}</div>
        <div className="mb-3 relative">
          <input
            type="text"
            className="form-control"
            placeholder={"201-555-0123"}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="labels-h">{"Card Details"}</div>
        <div className="row g-3">
          <div className="col-12">
            <CardNumberElement
              className="CardNumberElement form-control"
              onChange={(e) => {
                console.log("CardNumberElement", e.target, e);
              }}
            />
          </div>
          <div className="col-6">
            <CardExpiryElement className="CardExpiryElement form-control" />
          </div>
          <div className="col-6">
            <CardCvcElement
              className="CardCvcElement form-control"
              onChange={(e) => {
                console.log("CardCvcElement", e.target, e);
              }}
            />
          </div>
          <div className="stripe-adress-component col-12">
            <AddressElement className="stripe-adress-component" options={{mode:"billing", ...addressElementOptions}} onChange={(e) => {
                console.log("Address", e.target, e);
                setCardName(e.value.name)
            }} />
          </div>
          <div className="col-12">
            <div className="card p-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value={secureMyInfo}
                  id="setSecureMyInfo"
                  onClick={() => setSecureMyInfo(!secureMyInfo)}
                />
                <label
                  style={{ fontWeight: 500, fontSize: 14 }}
                  class="form-check-label"
                  for="setSecureMyInfo"
                >
                  Securely save my information for 1-click checkout
                </label>
                <div style={{ fontSize: "13px" }}>
                  Pay faster on Anew and everywhere Link is accepted.
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card p-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value={termsAndCondition}
                  id="termsAndCondition"
                  onClick={() => setTermsAndCondition(!termsAndCondition)}
                />
                <label
                  style={{ fontWeight: 500, fontSize: 14 }}
                  class="form-check-label"
                  for="termsAndCondition"
                >
                  I have read and agreed to the {" "}
                  <a href="https://www.loveanew.co/user-agreement" target="_blank">
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
        <button disabled={!allowToPay()} className="btn mt-3 w-100 stripe-pay-btn">
          Pay and start trial
        </button>
      </form>
    </div>
  );
};
export default CheckoutForm;
