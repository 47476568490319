import React, { useState } from "react";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import ProductDetails from "./ProductDetails";
import CheckoutForm from "./CheckoutForm";
import { getStripe } from "../../../utils/getStripe";
import "./checkout.css";

const PaymentCheckout = () => {
  const [couponDetails, setCouponDetails] = useState(null);

  const variables = {
    colorPrimary: '#1a1a1ab3 !important',
    colorBackground: '#ffffff',
    colorText: '#1a1a1ab3 !important',
    color: '#1a1a1ab3 !important',
    fontFamily: 'inherit !important',
    borderRadius: '5px',
    fontWeight:'500',
    labelFontWeight:'500',
    'labelSmFontWeight':'500',
    'labelMdFontWeight':'500',
    'labelXsFontWeight':'500'
  }
  const rules = {
    '.Label': {
      'color': '#1a1a1ab3',
      '--fontFamily': 'inherit !important',
      'font-size':'13px !important',
      '--fontSizeSm':'13px !important',
      // 'fontWeightNormal':'600 !important',
      // 'fontWeightNormal':'600 !important',
      'fontSize':'13px !important',
      // 'font-weight': 500,
      'labelSmFontWeight':'500',
      'labelMdFontWeight':'500',
      'labelXsFontWeight':'500'
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-2"></div>
        <Elements stripe={getStripe()} options={{ appearance: { variables, rules }}}>
        <ProductDetails setCouponDetails={setCouponDetails}/>
        <div className="col-lg-5 mt-5">
          <CheckoutForm couponDetails={couponDetails} />
        </div>
        </Elements>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default PaymentCheckout;
