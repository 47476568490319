import "./App.css";

import { useLocation } from "react-router-dom";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import Sidebar from "./Shared/Sidebar";
import HeaderBar from "./Shared/Headerbar";
import AppRouter from "./Approuter";
import "react-toastify/dist/ReactToastify.css";
import AuthRouter from "./AuthRouter";
import { ROUTES } from "./routes/routes";
import { isOnBoarding } from "./utils/routes.utils";

const { PLANS, LOGIN, SIGNUP, CHECKOUT } = ROUTES;

function App() {
  const location = useLocation();
  const { pathname } = location;

  const showSidebarAndHeader = () => {
    const publicRoutes = [LOGIN, SIGNUP];
    return !publicRoutes.includes(pathname);
  };



  const isCheckoutRoutes = () => {
    if(pathname.includes(CHECKOUT) || pathname.includes(PLANS)) return true;
    else return false;
  }

  return (
    <div className="container-fluid p-0">
      <div className="d-flex flex-column flex-lg-row">
        <SignedIn>
          <div className="p-0">
            {showSidebarAndHeader() && !isOnBoarding(pathname) && !isCheckoutRoutes() && <Sidebar />}
          </div>
          <div className="p-0 w-100 overflow-auto" style={{ backgroundColor: "#f5f5f5", height:'100vh' }}>
            {showSidebarAndHeader() && !isOnBoarding(pathname) && !isCheckoutRoutes() && <HeaderBar />}
            <div
              className={`${isOnBoarding(pathname) || isCheckoutRoutes() ? "" : "container pt-3"} ${
                showSidebarAndHeader() ? "" : "auth-page"
              }`}
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <AppRouter />
            </div>
          </div>
        </SignedIn>
        <SignedOut>
          <div className="p-0 w-100" style={{ backgroundColor: "#f5f5f5" }}>
            <div
              className={`container pt-3 ${
                showSidebarAndHeader() ? "" : "auth-page"
              }`}
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <AuthRouter />
            </div>
          </div>
        </SignedOut>
      </div>
    </div>
  );
}

export default App;
