import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import "./income.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { IncomeService } from '../../services/IncomeService';

const validationSchema = Yup.object().shape({
  yourAnnualGross: Yup.string().required('Required field.'),
  spousesAnnualGross: Yup.string().required('Required field.'),
  yourMonthlyIncome: Yup.string(),
  spousesMonthlyIncome: Yup.string(),
});

const Income = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const incomeService = new IncomeService();
  const [recordId, setRecordId] = useState("");
  const formik = useFormik({
    initialValues: {
      yourAnnualGross: '',
      spousesAnnualGross: '',
      yourMonthlyIncome: '',
      spousesMonthlyIncome: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.userId = user.id;

      if (recordId) {
        incomeService.update(recordId, values)
        return
      }
      incomeService.create(values)
    },
  });

  const handleYourAnnualGrossChange = (e) => {
    const newValue = e.target.value;
    formik.handleChange(e);

    const monthlyIncome = newValue !== '' ? (parseFloat(newValue) / 12).toFixed(2) : '';
    formik.setFieldValue('yourMonthlyIncome', monthlyIncome);
  };

  const handleSpousesAnnualGrossChange = (e) => {
    const newValue = e.target.value;
    formik.handleChange(e);

    const monthlyIncome = newValue !== '' ? (parseFloat(newValue) / 12).toFixed(2) : '';
    formik.setFieldValue('spousesMonthlyIncome', monthlyIncome);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    setTimeout(() => {
      console.log(formik.errors);
      if (Object.keys(formik.errors).length === 0) {
        toast.success("Income data saved");
      }
    }, 300)   
  };

  const handleContinue = () => {
    formik.handleSubmit();
    setTimeout(() => {
      console.log(formik.errors);
      if (Object.keys(formik.errors).length === 0) {
        navigate('/monthly-expense');
      }
    }, 250)
  };

  const getData = () => {
    incomeService.getData({ "userId": user.id }).then((response) => {
      if (response.data?.length) {
        formik.setValues(response.data[0]);
        setRecordId(response.data[0]._id)
      }
    })
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container col-sm-12 border">
        <div className="p-5">
          <div className="heading text-center">What’s your annual gross adjusted income?</div>
          <p className="paragraph mt-3">
            Your adjusted gross income (AGI) is your total (gross) income from all sources minus certain adjustments such as educator expenses, student loan interest, alimony payments and retirement contributions.
            If you use software to prepare your return, it will automatically calculate your AGI. Your AGI is entered on line 11 of <a href="https://www.irs.gov/forms-pubs/about-form-1040" target="_blank">Form 1040, U.S. Individual Income Tax Return.</a>
          </p>
        </div>
      </div>
      <div className="container col-sm-12 mt-4">
        <form>
          <div className="row mt-2 input-row">
            <div className="row d-flex align-items-center mb-2">
              <div className="col-md-6">
                <label className="form-label input-label">Your annual gross adjusted income</label>
              </div>
              <div className="col-md-4">
                <input type="number" name='yourAnnualGross' className="form-control mb-1"
                  value={formik.values.yourAnnualGross}
                  onChange={handleYourAnnualGrossChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.yourAnnualGross && formik.errors.yourAnnualGross && (
                  <div className="text-danger">{formik.errors.yourAnnualGross}</div>
                )}
              </div>
            </div>

            <div className="row d-flex align-items-center mb-2">
              <div className="col-md-6">
                <label className="form-label input-label">Your spouse's annual gross adjusted income</label>
              </div>
              <div className="col-md-4">
                <input type="number" name='spousesAnnualGross' className="form-control mb-1"
                  value={formik.values.spousesAnnualGross}
                  onChange={handleSpousesAnnualGrossChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.spousesAnnualGross && formik.errors.spousesAnnualGross && (
                  <div className="text-danger">{formik.errors.spousesAnnualGross}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-2 input-row">
            <div className="row d-flex align-items-center mb-2">
              <div className="col-md-6">
                <label className="form-label input-label">Your monthly income</label>
              </div>
              <div className="col-md-4">
                <input type="number" name="yourMonthlyIncome" className="form-control mb-1"
                  value={formik.values.yourMonthlyIncome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  readOnly={true}
                />
                {formik.touched.yourMonthlyIncome && formik.errors.yourMonthlyIncome && (
                  <div className="text-danger">{formik.errors.yourMonthlyIncome}</div>
                )}
              </div>
            </div>

            <div className="row d-flex align-items-center mb-2">
              <div className="col-md-6">
                <label className="form-label input-label">Your spouse’s monthly income</label>
              </div>
              <div className="col-md-4">
                <input type="number" name="spousesMonthlyIncome" className="form-control mb-1"
                  value={formik.values.spousesMonthlyIncome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  readOnly={true}
                />
                {formik.touched.spousesMonthlyIncome && formik.errors.spousesMonthlyIncome && (
                  <div className="text-danger">{formik.errors.spousesMonthlyIncome}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-10 col-md-10 col-sm-12 d-flex justify-content-end order-lg-last order-md-last order-sm-first">
              <button type="submit" className="btn btn-size btn-secondary mb-sm-10 me-2" onClick={handleSubmit}>Save</button>
              <button type="button" className="btn btn-size btn-primary" onClick={handleContinue}>Continue</button>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-center mb-3 mb-sm-0">
              <Link to="/" className="nav-link"><i className="fa fa-chevron-left mt-3 me-2"></i> Back</Link>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}

export default Income;
